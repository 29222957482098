"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("./../../environments/environment");
var logger_service_1 = require("../core/logger.service");
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var fromRoot = require("../reducers");
var models_1 = require("../shared/models");
var sync_crypt_service_1 = require("./crypt/sync-crypt.service");
var auth_service_1 = require("../auth/services/auth.service");
var api_service_1 = require("./api.service");
var multiadmin_model_1 = require("../shared/models/multiadmin.model");
// const data: DataField[] = [
var seedFields = [
    {
        'type': models_1.FieldTypes.TEXTBOX,
        'identity': models_1.FieldIdentities.ROLENAME,
        'label': 'Role Name',
        'required': true,
        'options': [],
        'defaultValue': '',
        'order': 0,
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANPURGEFILES,
        'label': 'Purge Files',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 1
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANMANAGEBILLING,
        'label': 'Manage billing',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 2
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANVIEWBILLING,
        'label': 'View billing',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 3
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANMANAGEUSERS,
        'label': 'Manage Users',
        'required': false,
        'options': [{ 'value': 0, 'key': 'Disabled' }, { 'value': 1, 'key': 'View only' }, { 'value': 2, 'key': 'View, add, edit' }],
        'defaultValue': 0,
        'order': 4
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANMANAGEROLES,
        'label': 'Manage Roles',
        'required': false,
        'options': [{ 'value': 0, 'key': 'Disabled' }, { 'value': 1, 'key': 'View only' }, { 'value': 2, 'key': 'View, add, edit' }],
        'defaultValue': 0,
        'order': 5
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANSHARELINKS,
        'label': 'Can Share Links',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 6,
        'subFieldsContext': 'The following settings will only applied to new Links created by users in this role, Existing Links created by these users will keep their current settings.',
        'subFields': [
            {
                'type': models_1.FieldTypes.RADIOBUTTON,
                'identity': models_1.FieldIdentities.CANSHARELINKPASSWORDREQUIRED,
                'label': 'Link Passwords',
                'required': false,
                'options': [{ 'value': false, 'key': 'Passwords Optional' }, { 'value': true, 'key': 'Passwords Required' }],
                'defaultValue': false,
                'order': 1,
            },
            {
                'type': models_1.FieldTypes.DROPDOWN,
                'identity': models_1.FieldIdentities.DEFAULTSHARELINKPASSWORD,
                'label': 'Default Password',
                'required': false,
                'subOf': models_1.FieldIdentities.CANSHARELINKPASSWORDREQUIRED,
                'options': [{ 'value': 0, 'key': 'Automatically generate a password' }, { 'value': 1, 'key': 'Require users to manually enter a password' }],
                'defaultValue': 1,
                'order': 2,
            },
            {
                'type': models_1.FieldTypes.RADIOBUTTON,
                'identity': models_1.FieldIdentities.CANSHARELINKEXPIRYREQUIRED,
                'label': 'Link Expiry Dates',
                'required': false,
                'options': [{ 'value': false, 'key': 'Expiry Dates Optional' }, { 'value': true, 'key': 'Expiry Dates Required' }],
                'defaultValue': false,
                'order': 3,
            },
            {
                'type': models_1.FieldTypes.DROPDOWN,
                'identity': models_1.FieldIdentities.DEFAULTSHARELINKEXPIRYTIME,
                'label': 'Default Expiry Time',
                'required': false,
                'subOf': models_1.FieldIdentities.CANSHARELINKEXPIRYREQUIRED,
                'options': multiadmin_model_1.linkExpiryDateOptions,
                'defaultValue': 7,
                'order': 4,
            }
        ]
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANSHAREFOLDERS,
        'label': 'Can Create Team Shares',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': true,
        'order': 7,
    },
    {
        'type': models_1.FieldTypes.DROPDOWN,
        'identity': models_1.FieldIdentities.CANFORCE2FALOGIN,
        'label': 'Force 2FA on First Login',
        'required': false,
        'options': [{ 'value': false, 'key': 'Disabled' }, { 'value': true, 'key': 'Enabled' }],
        'defaultValue': false,
        'order': 8
    }
];
var MultiAdminService = /** @class */ (function () {
    function MultiAdminService(httpClient, log, api, crypt, authService, store) {
        var _this = this;
        this.httpClient = httpClient;
        this.log = log;
        this.api = api;
        this.crypt = crypt;
        this.authService = authService;
        this.store = store;
        this.path = environment_1.environment.multiadminhost;
        this.seedFields = seedFields;
        this.store
            .pipe(store_1.select(fromRoot.getAuthUser))
            .subscribe(function (data) {
            if (data) {
                _this.user = data;
            }
            else {
                _this.user = null;
            }
        });
        this.state = this.store.select(fromRoot.getCoreState);
        this.state.subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.token = data['multiadmin_jwt_token'];
                return [2 /*return*/];
            });
        }); });
    }
    MultiAdminService.prototype.retrieveJwtToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaults, input, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.token || this.token === '')) return [3 /*break*/, 3];
                        defaults = new models_1.BaseApiInput();
                        defaults.servtime = Date.now();
                        return [4 /*yield*/, this.crypt.signApiReq(defaults)];
                    case 1:
                        input = _a.sent();
                        return [4 /*yield*/, this.getToken(input, this.user.id, this.user.team_id)];
                    case 2:
                        data = _a.sent();
                        if (data.success && data.authenticated) {
                            this.token = data.token;
                            this.authService.storeJwtToken(data.token);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, this.token];
                }
            });
        });
    };
    MultiAdminService.prototype.getToken = function (params, userId, teamId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "gettoken/" + teamId + "/" + userId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, params)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_1 = _a.sent();
                        this.handleError(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.getUserList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, activeUsersUrl, invitedUsersUrl, activeUsers_1, invitedUsers, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        activeUsersUrl = this.path + "users";
                        invitedUsersUrl = this.path + "users/invites";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, this.httpClient
                                .get(activeUsersUrl, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        activeUsers_1 = _a.sent();
                        return [4 /*yield*/, this.httpClient
                                .get(invitedUsersUrl, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 4:
                        invitedUsers = _a.sent();
                        invitedUsers.users.forEach(function (user) {
                            var obj = new models_1.MultiAdminUserData();
                            obj.email = user.email;
                            obj.user_status_str = user.status;
                            obj.user_id = (user.userId) ? user.userId : user.id;
                            obj.job_id = user.id;
                            obj.roles = [user.role];
                            obj.isExpired = user.isExpired;
                            obj.isLegacyToCNC = user.isLegacyToCNC;
                            activeUsers_1.users.push(obj);
                        });
                        this.log.info(invitedUsersUrl + ": successful - resp " + JSON.stringify(invitedUsers));
                        return [2 /*return*/, Promise.resolve(activeUsers_1)];
                    case 5:
                        err_2 = _a.sent();
                        this.handleError(err_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.getRoles = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "roles";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .get(url, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_3 = _a.sent();
                        this.handleError(err_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.getCurrentUserPermissions = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/" + userId;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .get(url, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_4 = _a.sent();
                        this.handleError(err_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.checkExistingUsers = function (users) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/existingUsers";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, users, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_5 = _a.sent();
                        this.handleError(err_5);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.addMultipleUsers = function (users) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/add";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, users, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_6 = _a.sent();
                        this.handleError(err_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.deleteProvisioningUser = function (identifier) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/invite/" + identifier;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .delete(url, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_7 = _a.sent();
                        this.handleError(err_7);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.deleteProvisionedUser = function (userId, roles) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/" + userId + "/" + roles[0];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .delete(url, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_8 = _a.sent();
                        this.handleError(err_8);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.updateUserRole = function (userId, currentRole, newRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/" + userId + "/" + currentRole;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .put(url, { newRole: newRole }, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_9 = _a.sent();
                        this.handleError(err_9);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.updateMultipleUsersRole = function (userRoles, newRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/updateMultipleUsersRole";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .put(url, { userRoles: userRoles, newRole: newRole }, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_10 = _a.sent();
                        this.handleError(err_10);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.resendInviteEmail = function (userId, email, role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/resendinviteemail";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, { userId: userId, email: email, role: role }, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_11 = _a.sent();
                        this.handleError(err_11);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.resenduserTeamInviteEmail = function (child) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "users/resenduserinviteemail";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, { userId: child.user_id }, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_12 = _a.sent();
                        this.handleError(err_12);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.deleteRole = function (roleName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, result, err_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "roles/" + roleName;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .delete(url, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        if (result && result.success) {
                            this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        }
                        return [2 /*return*/, Promise.resolve(result.success || false)];
                    case 4:
                        err_13 = _a.sent();
                        this.handleError(err_13);
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Making an API call for adding a new role
     * @param role {Role} payload of a new role
     */
    MultiAdminService.prototype.addRole = function (role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, apiBody, result, err_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "roles/" + role.name;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        apiBody = role.permissions;
                        return [4 /*yield*/, this.httpClient
                                .post(url, apiBody, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_14 = _a.sent();
                        this.handleError(err_14);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Making an API call for updating a role
     * @param oldRoleObject {Role} role object before it was updated
     * @param updatedRole   {Role} role object after it was updated
     */
    MultiAdminService.prototype.addUpdateRole = function (oldRoleObject, updatedRole) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, url, apiBody, result, err_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.retrieveJwtToken()];
                    case 1:
                        token = _a.sent();
                        url = this.path + "roles/" + oldRoleObject.name;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        apiBody = void 0;
                        if (oldRoleObject.name !== updatedRole.name) {
                            apiBody = { permissions: updatedRole.permissions, newRoleName: updatedRole.name };
                        }
                        else {
                            apiBody = { permissions: updatedRole.permissions };
                        }
                        return [4 /*yield*/, this.httpClient
                                .put(url, apiBody, { headers: { 'x-authorization': token } })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful - resp " + JSON.stringify(result));
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_15 = _a.sent();
                        this.handleError(err_15);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.verifyEmailInvite = function (confirmcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "users/consentinvite/" + confirmcode;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .get(url)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_16 = _a.sent();
                        this.handleError(err_16);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.verifyLegacyUserEmailInvite = function (confirmcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "users/legacyuserconsentinvite/" + confirmcode;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .get(url)
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_17 = _a.sent();
                        this.handleError(err_17);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.sendEmailToTeamMembers = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "users/sendemailtoteammembersupdateapp";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, { userId: userId })
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_18 = _a.sent();
                        this.handleError(err_18);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.handleDeclinedInviteConsent = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, result, err_19;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.path + "users/handleDeclinedInviteConsent";
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, { userId: userId })
                                .toPromise()];
                    case 2:
                        result = _a.sent();
                        this.log.info(url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 3:
                        err_19 = _a.sent();
                        this.handleError(err_19);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.getPwdKeysData = function (email, password, twofacode, isLegacyToCNC) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashPass, keyData, ex_1, pubkey, passResetKeys, encPassMetaOld, encPassPrivOld;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.getHashedPassword(email, password, true)];
                    case 1:
                        hashPass = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.authService.authenticateAndGetUserKeys(email, hashPass, twofacode, isLegacyToCNC)];
                    case 3:
                        keyData = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        if (ex_1.code === 8022) {
                            throw new models_1.ErrCode(ex_1.code);
                        }
                        else {
                            throw new models_1.ErrCode(6001, 'Incorrect login credentials');
                        }
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.api.fetchText('/key/password-recover/pubkey')];
                    case 6:
                        pubkey = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetPasswords(password, pubkey)];
                    case 7:
                        passResetKeys = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_meta_key, password, pubkey)];
                    case 8:
                        encPassMetaOld = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_priv_key, password, pubkey)];
                    case 9:
                        encPassPrivOld = _a.sent();
                        return [2 /*return*/, {
                                enc_pass_meta: passResetKeys.encPassMeta,
                                salt_meta: passResetKeys.metaSalt,
                                enc_pass_priv: passResetKeys.encPassPriv,
                                salt_priv: passResetKeys.privSalt,
                                password_hash: hashPass,
                                encPassMetaOld: encPassMetaOld,
                                encPassPrivOld: encPassPrivOld
                            }];
                }
            });
        });
    };
    // slightly different then getPwdKeysData as this will only be called
    // when a user is already authenticated and we do NOT need to re-auth.
    // kept this method separate so any future changes won't affect any other working
    MultiAdminService.prototype.getPwdKeysDataAuthenticated = function (email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var hashPass, keyData, ex_2, pubkey, passResetKeys, encPassMetaOld, encPassPrivOld;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.getHashedPassword(email, password, true)];
                    case 1:
                        hashPass = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.api.send('getuserkeys', {
                                username: email,
                                password: hashPass,
                            })];
                    case 3:
                        keyData = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        ex_2 = _a.sent();
                        if (ex_2 && ex_2.code) {
                            switch (ex_2.code) {
                                case 8022:
                                case 8024: // bad enc_password
                                    // these are errors we want to throw specifically back
                                    // to the component to handle.
                                    this.log.warn("Error retrieiving encrypted user keys \"" + ex_2.code + "\"");
                                    throw new models_1.ErrCode(ex_2.code);
                                default:
                                    // else throw default error code
                                    throw new models_1.ErrCode(6001, 'Incorrect login credentials');
                            }
                        }
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, this.api.fetchText('/key/password-recover/pubkey')];
                    case 6:
                        pubkey = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetPasswords(password, pubkey)];
                    case 7:
                        passResetKeys = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_meta_key, password, pubkey)];
                    case 8:
                        encPassMetaOld = _a.sent();
                        return [4 /*yield*/, this.crypt.prepareResetKey(keyData.enc_priv_key, password, pubkey)];
                    case 9:
                        encPassPrivOld = _a.sent();
                        return [2 /*return*/, {
                                enc_pass_meta: passResetKeys.encPassMeta,
                                salt_meta: passResetKeys.metaSalt,
                                enc_pass_priv: passResetKeys.encPassPriv,
                                salt_priv: passResetKeys.privSalt,
                                password_hash: hashPass,
                                encPassMetaOld: encPassMetaOld,
                                encPassPrivOld: encPassPrivOld
                            }];
                }
            });
        });
    };
    MultiAdminService.prototype.handleAcceptedInviteConsent = function (tokenData, confirmCode, password, twofacode, isLegacyToCNC) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var saltData, url, result, err_20;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPwdKeysData(tokenData.email, password, twofacode, isLegacyToCNC)];
                    case 1:
                        saltData = _a.sent();
                        url = this.path + "users/handleAcceptedInviteConsent";
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.httpClient
                                .post(url, {
                                tokenData: tokenData, saltData: tslib_1.__assign({}, saltData, { cachekey: confirmCode })
                            })
                                .toPromise()];
                    case 3:
                        result = _a.sent();
                        this.log.info(url + ": successful");
                        return [2 /*return*/, Promise.resolve(result)];
                    case 4:
                        err_20 = _a.sent();
                        this.handleError(err_20);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MultiAdminService.prototype.statusHandler = function (status) {
        switch (status) {
            case 'pending':
                return 'pending';
            case 'pending downgrade':
                return 'migrating';
            case 'working':
                return 'working';
            case 'email sent':
                return 'email sent';
            case 'invited':
                return 'invited';
            case 'declined':
                return 'declined';
            case 'error':
                return 'error';
            default:
                return status;
        }
    };
    MultiAdminService.prototype.getPermissionValue = function (role, object, identity, parentIdentity) {
        var permission = role.permissions.filter(function (permission) { return permission.object === object && permission.action === identity; });
        if (parentIdentity) {
            var parentPermission = role.permissions.filter(function (permission) { return permission.object === object && permission.action === parentIdentity; });
            var hidden = parentIdentity === models_1.PermissionActions.DEFAULT_SHARE_LINK_PASSWORD ? true : parentPermission[0].hidden;
            return permission.length > 0 ? { isHidden: hidden, isEditable: permission[0].editable, value: parentPermission[0].subValue } : undefined;
        }
        else {
            return permission.length > 0 ? { isHidden: permission[0].hidden, isEditable: permission[0].editable, value: permission[0].value } : undefined;
        }
    };
    MultiAdminService.prototype.getValueFromField = function (newValues, field) {
        try {
            if (newValues !== null) {
                switch (field.identity) {
                    case models_1.FieldIdentities.ROLENAME: {
                        return { isHidden: false, isEditable: false, value: newValues.name };
                    }
                    case models_1.FieldIdentities.CANMANAGEUSERS: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.MANAGE_USERS);
                    }
                    case models_1.FieldIdentities.CANVIEWUSERS: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.VIEW_USERS);
                    }
                    case models_1.FieldIdentities.CANADDEDITUSERS: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.ADD_EDIT_USERS);
                    }
                    case models_1.FieldIdentities.CANMANAGEROLES: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.MANAGE_ROLES);
                    }
                    case models_1.FieldIdentities.CANVIEWROLES: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.VIEW_ROLES);
                    }
                    case models_1.FieldIdentities.CANADDEDITROLES: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.ADMIN, models_1.PermissionActions.ADD_EDIT_ROLES);
                    }
                    case models_1.FieldIdentities.CANVIEWBILLING: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.BILLING, models_1.PermissionActions.VIEW_BILLING);
                    }
                    case models_1.FieldIdentities.CANMANAGEBILLING: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.BILLING, models_1.PermissionActions.MANAGE_BILLING);
                    }
                    case models_1.FieldIdentities.CANFORCE2FALOGIN: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SETTINGS, models_1.PermissionActions.FORCE_2FA_ON_FIRST_LOGIN);
                    }
                    case models_1.FieldIdentities.CANPURGEFILES: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.FILES, models_1.PermissionActions.PURGE_FILES);
                    }
                    case models_1.FieldIdentities.CANSHARELINKS: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SHARE, models_1.PermissionActions.SHARE_LINKS);
                    }
                    case models_1.FieldIdentities.CANSHARELINKEXPIRYREQUIRED: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SHARE, models_1.PermissionActions.SHARE_LINK_EXPIRY);
                    }
                    case models_1.FieldIdentities.DEFAULTSHARELINKEXPIRYTIME: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SHARE, models_1.PermissionActions.DEFAULT_SHARE_LINK_EXPIRY_TIME, models_1.PermissionActions.SHARE_LINK_EXPIRY);
                    }
                    case models_1.FieldIdentities.CANSHARELINKPASSWORDREQUIRED: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SHARE, models_1.PermissionActions.SHARE_LINK_PASSWORD);
                    }
                    case models_1.FieldIdentities.DEFAULTSHARELINKPASSWORD: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.SHARE, models_1.PermissionActions.DEFAULT_SHARE_LINK_PASSWORD, models_1.PermissionActions.SHARE_LINK_PASSWORD);
                    }
                    case models_1.FieldIdentities.CANSHAREFOLDERS: {
                        return this.getPermissionValue(newValues, models_1.PermissionObjects.FOLDERS, models_1.PermissionActions.SHARE_FOLDERS);
                    }
                    default: {
                        return;
                    }
                }
            }
        }
        catch (ex) {
            if (ex instanceof TypeError) {
                // We have tried to access an unknown field
                return;
            }
            else {
                throw (ex);
            }
        }
    };
    MultiAdminService.prototype.createFields = function (newValues, disableRoleName) {
        var _this = this;
        var createdFields = [];
        this.seedFields.forEach(function (field, index) {
            var props = _this.getValueFromField(newValues, field);
            if (props === undefined) {
                // Values aren't available for this field from the new role, skip it.
                return;
            }
            if (field.type === models_1.FieldTypes.TEXTBOX) {
                createdFields.push(new models_1.Field({
                    controlType: models_1.FieldTypes.TEXTBOX,
                    key: field.identity,
                    label: field.label,
                    value: props.value,
                    required: field.required,
                    type: 'text',
                    order: field.order,
                    disabled: index === 0 ? disableRoleName : !props.isEditable,
                    hidden: props.isHidden
                }));
            }
            else if (field.type === models_1.FieldTypes.DROPDOWN) {
                createdFields.push(new models_1.Field({
                    controlType: models_1.FieldTypes.DROPDOWN,
                    key: field.identity,
                    label: field.label,
                    options: field.options,
                    value: props.value,
                    required: field.required,
                    order: field.order,
                    disabled: !props.isEditable,
                    hidden: props.isHidden,
                    subFieldContext: field.subFieldsContext,
                    subFields: field.subFields && field.subFields.length ? field.subFields.map(function (subField, subIndex) {
                        var subProps = _this.getValueFromField(newValues, subField);
                        if (subProps === undefined) {
                            // Values aren't available for this field from the new role, skip it.
                            return;
                        }
                        if (subField.type === models_1.FieldTypes.DROPDOWN) {
                            return new models_1.Field({
                                controlType: models_1.FieldTypes.DROPDOWN,
                                key: subField.identity,
                                label: subField.label,
                                options: subField.options,
                                value: subProps.value,
                                required: subField.required,
                                order: subField.order,
                                disabled: !subProps.isEditable,
                                hidden: subProps.isHidden,
                                subOf: subField.subOf
                            });
                        }
                        else if (subField.type === models_1.FieldTypes.RADIOBUTTON) {
                            return new models_1.Field({
                                controlType: models_1.FieldTypes.RADIOBUTTON,
                                key: subField.identity,
                                label: subField.label,
                                options: subField.options,
                                value: subProps.value,
                                required: subField.required,
                                type: 'radio',
                                order: subField.order,
                                disabled: !subProps.isEditable,
                                hidden: subProps.isHidden
                            });
                        }
                    }) : []
                }));
            }
            else {
                createdFields.push(new models_1.Field({
                    key: field.identity,
                    label: field.label,
                    order: field.order,
                    disabled: !props.isEditable,
                    hidden: false
                }));
            }
        });
        return createdFields.sort(function (a, b) { return a.order - b.order; });
    };
    MultiAdminService.prototype.handleError = function (response) {
        if (response.status) {
            switch (response.status) {
                case 422:
                    this.log.error("error status " + response.status + ": " + response.error.message + ", detail: " + JSON.stringify(response.error.detail, null, 4));
                    break;
                case 429:
                    this.log.error("error status " + response.status + ": " + response.error.message);
                    break;
                default:
                    this.log.error("error status " + response.status + ": " + response.error.message + "\n detail:" + JSON.stringify(response.error.detail, null, 4));
            }
        }
        else {
            this.log.error(JSON.stringify(response));
        }
        throw response;
    };
    return MultiAdminService;
}());
exports.MultiAdminService = MultiAdminService;
