import { environment } from './../../environments/environment';
import { LoggerService } from '../core/logger.service';
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpErrorResponse
} from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromCore from '../reducers/core.reducer';
import { BaseApiInput, ErrCode, Field, FieldIdentities, FieldTypes, GetUserKeysApiOutput, JwtTokenApiOutput, MultiAdminUserData, Permission, PermissionActions, PermissionObjects, Role, SessionNewApiOutput, StandardField, User, UserWithPermissions } from '../shared/models';
import { Observable } from 'rxjs';
import { SyncCryptService } from './crypt/sync-crypt.service';
import { AuthService } from '../auth/services/auth.service';
import { ApiService } from './api.service';
import { EmailsExistsApiOutput, MultiAdminAddUsersOutput, MultiAdminUserListApiOutput } from '../shared/models/api/multiadmin.model';
import { MultiAdminSeedField, ValidateTokenOutput, linkExpiryDateOptions } from '../shared/models/multiadmin.model';


// const data: DataField[] = [
const seedFields: MultiAdminSeedField[] = [
    {
        'type': FieldTypes.TEXTBOX,
        'identity': FieldIdentities.ROLENAME,
        'label': 'Role Name',
        'required': true,
        'options': [],
        'defaultValue': '',
        'order': 0,

    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANPURGEFILES,
        'label': 'Purge Files',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 1
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANMANAGEBILLING,
        'label': 'Manage billing',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 2
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANVIEWBILLING,
        'label': 'View billing',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 3
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANMANAGEUSERS,
        'label': 'Manage Users',
        'required': false,
        'options': [{ 'value': 0, 'key': 'Disabled' }, { 'value': 1, 'key': 'View only' }, { 'value': 2, 'key': 'View, add, edit' }],
        'defaultValue': 0,
        'order': 4
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANMANAGEROLES,
        'label': 'Manage Roles',
        'required': false,
        'options': [{ 'value': 0, 'key': 'Disabled' }, { 'value': 1, 'key': 'View only' }, { 'value': 2, 'key': 'View, add, edit' }],
        'defaultValue': 0,
        'order': 5
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANSHARELINKS,
        'label': 'Can Share Links',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': false,
        'order': 6,
        'subFieldsContext': 'The following settings will only applied to new Links created by users in this role, Existing Links created by these users will keep their current settings.',
        'subFields': [
            {
                'type': FieldTypes.RADIOBUTTON,
                'identity': FieldIdentities.CANSHARELINKPASSWORDREQUIRED,
                'label': 'Link Passwords',
                'required': false,
                'options': [{ 'value': false, 'key': 'Passwords Optional' }, { 'value': true, 'key': 'Passwords Required' }],
                'defaultValue': false,
                'order': 1,
            },
            {
                'type': FieldTypes.DROPDOWN,
                'identity': FieldIdentities.DEFAULTSHARELINKPASSWORD,
                'label': 'Default Password',
                'required': false,
                'subOf': FieldIdentities.CANSHARELINKPASSWORDREQUIRED,
                'options': [{ 'value': 0, 'key': 'Automatically generate a password' }, { 'value': 1, 'key': 'Require users to manually enter a password' }],
                'defaultValue': 1,
                'order': 2,
            },
            {
                'type': FieldTypes.RADIOBUTTON,
                'identity': FieldIdentities.CANSHARELINKEXPIRYREQUIRED,
                'label': 'Link Expiry Dates',
                'required': false,
                'options': [{ 'value': false, 'key': 'Expiry Dates Optional' }, { 'value': true, 'key': 'Expiry Dates Required' }],
                'defaultValue': false,
                'order': 3,
            },
            {
                'type': FieldTypes.DROPDOWN,
                'identity': FieldIdentities.DEFAULTSHARELINKEXPIRYTIME,
                'label': 'Default Expiry Time',
                'required': false,
                'subOf': FieldIdentities.CANSHARELINKEXPIRYREQUIRED,
                'options': linkExpiryDateOptions,
                'defaultValue': 7,
                'order': 4,
            }
        ]
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANSHAREFOLDERS,
        'label': 'Can Create Team Shares',
        'required': false,
        'options': [{ 'value': true, 'key': 'Enabled' }, { 'value': false, 'key': 'Disabled' }],
        'defaultValue': true,
        'order': 7,
    },
    {
        'type': FieldTypes.DROPDOWN,
        'identity': FieldIdentities.CANFORCE2FALOGIN,
        'label': 'Force 2FA on First Login',
        'required': false,
        'options': [{ 'value': false, 'key': 'Disabled' }, { 'value': true, 'key': 'Enabled' }],
        'defaultValue': false,
        'order': 8
    }
];

@Injectable()
export class MultiAdminService {
    private path = environment.multiadminhost;
    private seedFields = seedFields;
    private state: Observable<fromCore.State>;
    private token: string;
    private user: User;

    constructor(
        private httpClient: HttpClient,
        private log: LoggerService,
        private api: ApiService,
        private crypt: SyncCryptService,
        private authService: AuthService,
        private store: Store<fromRoot.State>,
    ) {
        this.store
            .pipe(select(fromRoot.getAuthUser))
            .subscribe((data) => {
                if (data) {
                    this.user = data;
                } else {
                    this.user = null;
                }
            });
        this.state = this.store.select(fromRoot.getCoreState);
        this.state.subscribe(async (data) => {
            this.token = data['multiadmin_jwt_token'];
        });
    }

    private async retrieveJwtToken(): Promise<string> {
        if (!this.token || this.token === '') {
            const defaults = new BaseApiInput();
            defaults.servtime = Date.now();
            const input = await this.crypt.signApiReq(defaults);
            const data = await this.getToken(input, this.user.id, this.user.team_id);
            if (data.success && data.authenticated) {
                this.token = data.token;
                this.authService.storeJwtToken(data.token);
            }
        }
        return this.token;
    }

    async getToken(params: BaseApiInput, userId: number, teamId: number): Promise<JwtTokenApiOutput> {
        const url = `${this.path}gettoken/${teamId}/${userId}`;

        try {
            //api call to multiadmin server, getting jwt authentication token
            const result = await this.httpClient
                .post<JwtTokenApiOutput>(url, params)
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async getUserList(): Promise<MultiAdminUserListApiOutput> {
        const token = await this.retrieveJwtToken();
        const activeUsersUrl = `${this.path}users`;
        const invitedUsersUrl = `${this.path}users/invites`;
        try {
            //api call to multiadmin server, getting active and invited users
            const activeUsers = await this.httpClient
                .get<MultiAdminUserListApiOutput>(activeUsersUrl, { headers: { 'x-authorization': token } })
                .toPromise();
            const invitedUsers = await this.httpClient
                .get<{ success: boolean, users: any[] }>(invitedUsersUrl, { headers: { 'x-authorization': token } })
                .toPromise();
            invitedUsers.users.forEach(user => {
                const obj = new MultiAdminUserData();
                obj.email = user.email;
                obj.user_status_str = user.status;
                obj.user_id = (user.userId) ? user.userId : user.id;
                obj.job_id = user.id;
                obj.roles = [user.role];
                obj.isExpired = user.isExpired;
                obj.isLegacyToCNC = user.isLegacyToCNC;
                activeUsers.users.push(obj);
            });
            this.log.info(`${invitedUsersUrl}: successful - resp ${JSON.stringify(invitedUsers)}`);
            return Promise.resolve(activeUsers);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async getRoles(): Promise<Role[]> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}roles`;

        try {
            //api call to multiadmin server, getting roles
            const result = await this.httpClient
                .get<Role[]>(url, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async getCurrentUserPermissions(userId: number): Promise<UserWithPermissions> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/${userId}`;

        try {
            //api call to multiadmin server, getting current user details with permissions
            const result = await this.httpClient
                .get<UserWithPermissions>(url, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async checkExistingUsers(users: { teamId: number; users: object }): Promise<EmailsExistsApiOutput> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/existingUsers`;

        try {
            //api call to multiadmin server, to check the existing users
            const result = await this.httpClient
                .post<EmailsExistsApiOutput>(url, users, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async addMultipleUsers(users: { teamId: number; users: object }): Promise<MultiAdminAddUsersOutput> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/add`;

        try {
            //api call to multiadmin server, adding multiple users for provisioning
            const result = await this.httpClient
                .post<MultiAdminAddUsersOutput>(url, users, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async deleteProvisioningUser(identifier: number | string): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/invite/${identifier}`;

        try {
            //api call to multiadmin server, deleting provisioning user
            const result = await this.httpClient
                .delete<{ success: boolean }>(url, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async deleteProvisionedUser(userId: number, roles: string[]): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/${userId}/${roles[0]}`;

        try {
            //api call to multiadmin server, deleting provisioned user - Email Sent
            const result = await this.httpClient
                .delete<{ success: boolean }>(url, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async updateUserRole(userId: number, currentRole: string, newRole: string): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/${userId}/${currentRole}`;

        try {
            //api call to multiadmin server, remove old role and assign new role
            const result = await this.httpClient
                .put<{ success: boolean }>(url, { newRole }, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async updateMultipleUsersRole(userRoles: { user_id: number, roleName: string }[], newRole: string): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/updateMultipleUsersRole`;

        try {
            //api call to multiadmin server, remove old roles and assign new role
            const result = await this.httpClient
                .put<{ success: boolean }>(url, { userRoles, newRole }, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async resendInviteEmail(userId: number, email: string, role: string): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/resendinviteemail`;

        try {
            //api call to multiadmin server, resending invite email
            const result = await this.httpClient
                .post<{ success: boolean }>(url, { userId: userId, email: email, role: role }, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async resenduserTeamInviteEmail(child: MultiAdminUserData): Promise<{ success: boolean }> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}users/resenduserinviteemail`;

        try {
            //api call to multiadmin server, resending invite email to existing user
            const result = await this.httpClient
                .post<{ success: boolean }>(url, { userId: child.user_id }, { headers: { 'x-authorization': token } })
                .toPromise();
            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async deleteRole(roleName: string): Promise<boolean> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}roles/${roleName}`;

        try {
            // api call to multiadmin server
            const result = await this.httpClient
                .delete<{ success: boolean }>(url, { headers: { 'x-authorization': token } })
                .toPromise();

            if (result && result.success) { this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`); }

            return Promise.resolve(result.success || false);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
            return false;
        }

    }

    /**
     * Making an API call for adding a new role
     * @param role {Role} payload of a new role
     */
    async addRole(role: Role): Promise<void> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}roles/${role.name}`;
        try {
            const apiBody = role.permissions;
            // api call to multiadmin server to create role
            const result = await this.httpClient
                .post<void>(url, apiBody, { headers: { 'x-authorization': token } })
                .toPromise();

            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    /**
     * Making an API call for updating a role
     * @param oldRoleObject {Role} role object before it was updated
     * @param updatedRole   {Role} role object after it was updated
     */
    async addUpdateRole(oldRoleObject: Role, updatedRole: Role): Promise<void> {
        const token = await this.retrieveJwtToken();
        const url = `${this.path}roles/${oldRoleObject.name}`;

        try {
            let apiBody;

            if (oldRoleObject.name !== updatedRole.name) {
                apiBody = { permissions: updatedRole.permissions, newRoleName: updatedRole.name };
            } else {
                apiBody = { permissions: updatedRole.permissions };
            }

            // api call to multiadmin server to updating role
            const result = await this.httpClient
                .put<void>(url, apiBody, { headers: { 'x-authorization': token } })
                .toPromise();

            this.log.info(`${url}: successful - resp ${JSON.stringify(result)}`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async verifyEmailInvite(confirmcode): Promise<ValidateTokenOutput> {
        const url = `${this.path}users/consentinvite/${confirmcode}`;

        try {
            //api call to multiadmin server, getting user invite details
            const result = await this.httpClient
                .get<ValidateTokenOutput>(url)
                .toPromise();
            this.log.info(`${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async verifyLegacyUserEmailInvite(confirmcode): Promise<ValidateTokenOutput> {
        const url = `${this.path}users/legacyuserconsentinvite/${confirmcode}`;

        try {
            //api call to multiadmin server, getting user invite details
            const result = await this.httpClient
                .get<ValidateTokenOutput>(url)
                .toPromise();
            this.log.info(`${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async sendEmailToTeamMembers(userId: number): Promise<{ success: boolean }> {
        const url = `${this.path}users/sendemailtoteammembersupdateapp`;

        try {
            //api call to multiadmin server, to decline invite consent
            const result = await this.httpClient
                .post<{ success: boolean }>(url, { userId })
                .toPromise();
            this.log.info(`${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async handleDeclinedInviteConsent(userId: number): Promise<{ success: boolean }> {
        const url = `${this.path}users/handleDeclinedInviteConsent`;

        try {
            //api call to multiadmin server, to decline invite consent
            const result = await this.httpClient
                .post<{ success: boolean }>(url, { userId })
                .toPromise();
            this.log.info(`${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    async getPwdKeysData(email: string, password: string, twofacode: string, isLegacyToCNC?: boolean) {
        const hashPass = await this.authService.getHashedPassword(email, password, true);
        let keyData: GetUserKeysApiOutput;
        try {
            keyData = await this.authService.authenticateAndGetUserKeys(email, hashPass, twofacode, isLegacyToCNC);
        } catch (ex) {
            if (ex.code === 8022) {
                throw new ErrCode(ex.code);
            } else {
                throw new ErrCode(6001, 'Incorrect login credentials');
            }
        }

        const pubkey = await this.api.fetchText('/key/password-recover/pubkey');
        const passResetKeys = await this.crypt.prepareResetPasswords(password, pubkey);
        const encPassMetaOld = await this.crypt.prepareResetKey(keyData.enc_meta_key, password, pubkey);
        const encPassPrivOld = await this.crypt.prepareResetKey(keyData.enc_priv_key, password, pubkey);

        return {
            enc_pass_meta: passResetKeys.encPassMeta,
            salt_meta: passResetKeys.metaSalt,
            enc_pass_priv: passResetKeys.encPassPriv,
            salt_priv: passResetKeys.privSalt,
            password_hash: hashPass,
            encPassMetaOld,
            encPassPrivOld
        };
    }

    // slightly different then getPwdKeysData as this will only be called
    // when a user is already authenticated and we do NOT need to re-auth.
    // kept this method separate so any future changes won't affect any other working
    async getPwdKeysDataAuthenticated(email: string, password: string) {
        const hashPass = await this.authService.getHashedPassword(email, password, true);
        let keyData: GetUserKeysApiOutput;
        try {
            keyData = await this.api.send<GetUserKeysApiOutput>(
                'getuserkeys',
                {
                    username: email,
                    password: hashPass,
                }
            );
            // return keys;
        } catch (ex) {
            if (ex && ex.code) {
                switch (ex.code) {
                    case 8022:
                    case 8024: // bad enc_password
                        // these are errors we want to throw specifically back
                        // to the component to handle.
                        this.log.warn(`Error retrieiving encrypted user keys "${ex.code}"`);
                        throw new ErrCode(ex.code);
                    default:
                        // else throw default error code
                        throw new ErrCode(6001, 'Incorrect login credentials');
                }
            }
        }
        const pubkey = await this.api.fetchText('/key/password-recover/pubkey');
        const passResetKeys = await this.crypt.prepareResetPasswords(password, pubkey);
        const encPassMetaOld = await this.crypt.prepareResetKey(keyData.enc_meta_key, password, pubkey);
        const encPassPrivOld = await this.crypt.prepareResetKey(keyData.enc_priv_key, password, pubkey);
        return {
            enc_pass_meta: passResetKeys.encPassMeta,
            salt_meta: passResetKeys.metaSalt,
            enc_pass_priv: passResetKeys.encPassPriv,
            salt_priv: passResetKeys.privSalt,
            password_hash: hashPass,
            encPassMetaOld,
            encPassPrivOld
        };
    }

    async handleAcceptedInviteConsent(tokenData: ValidateTokenOutput, confirmCode: string, password: string, twofacode: string, isLegacyToCNC?: boolean): Promise<{ success: boolean }> {
        const saltData = await this.getPwdKeysData(tokenData.email, password, twofacode, isLegacyToCNC);

        const url = `${this.path}users/handleAcceptedInviteConsent`;

        try {
            //api call to multiadmin server, to accept invite consent
            const result = await this.httpClient
                .post<{ success: boolean }>(url, {
                    tokenData, saltData: {
                        ...saltData,
                        cachekey: confirmCode
                    }
                })
                .toPromise();
            this.log.info(`${url}: successful`);
            return Promise.resolve(result);
        } catch (err) {
            this.handleError(err as HttpErrorResponse);
        }
    }

    public statusHandler(status: string): string {
        switch (status) {
            case 'pending':
                return 'pending';
            case 'pending downgrade':
                return 'migrating';
            case 'working':
                return 'working';
            case 'email sent':
                return 'email sent';
            case 'invited':
                return 'invited';
            case 'declined':
                return 'declined';
            case 'error':
                return 'error';
            default:
                return status;
        }
    }

    private getPermissionValue(role: Role, object: string, identity: string, parentIdentity?: string) {
        const permission = role.permissions.filter((permission: Permission) => permission.object === object && permission.action === identity);
        if (parentIdentity) {
            const parentPermission = role.permissions.filter((permission: Permission) => permission.object === object && permission.action === parentIdentity);
            const hidden = parentIdentity === PermissionActions.DEFAULT_SHARE_LINK_PASSWORD ? true : parentPermission[0].hidden;
            return permission.length > 0 ? { isHidden: hidden, isEditable: permission[0].editable, value: parentPermission[0].subValue } : undefined;
        } else {
            return permission.length > 0 ? { isHidden: permission[0].hidden, isEditable: permission[0].editable, value: permission[0].value } : undefined;
        }
    }

    private getValueFromField(newValues: Role, field: MultiAdminSeedField): { isHidden: boolean; isEditable: boolean; value: string | boolean | number } | undefined {
        try {
            if (newValues !== null) {
                switch (field.identity) {
                    case FieldIdentities.ROLENAME: {
                        return { isHidden: false, isEditable: false, value: newValues.name };
                    }
                    case FieldIdentities.CANMANAGEUSERS: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.MANAGE_USERS);
                    }
                    case FieldIdentities.CANVIEWUSERS: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.VIEW_USERS);
                    }
                    case FieldIdentities.CANADDEDITUSERS: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.ADD_EDIT_USERS);
                    }
                    case FieldIdentities.CANMANAGEROLES: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.MANAGE_ROLES);
                    }
                    case FieldIdentities.CANVIEWROLES: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.VIEW_ROLES);
                    }
                    case FieldIdentities.CANADDEDITROLES: {
                        return this.getPermissionValue(newValues, PermissionObjects.ADMIN, PermissionActions.ADD_EDIT_ROLES);
                    }
                    case FieldIdentities.CANVIEWBILLING: {
                        return this.getPermissionValue(newValues, PermissionObjects.BILLING, PermissionActions.VIEW_BILLING);
                    }
                    case FieldIdentities.CANMANAGEBILLING: {
                        return this.getPermissionValue(newValues, PermissionObjects.BILLING, PermissionActions.MANAGE_BILLING);
                    }
                    case FieldIdentities.CANFORCE2FALOGIN: {
                        return this.getPermissionValue(newValues, PermissionObjects.SETTINGS, PermissionActions.FORCE_2FA_ON_FIRST_LOGIN);
                    }
                    case FieldIdentities.CANPURGEFILES: {
                        return this.getPermissionValue(newValues, PermissionObjects.FILES, PermissionActions.PURGE_FILES);
                    }
                    case FieldIdentities.CANSHARELINKS: {
                        return this.getPermissionValue(newValues, PermissionObjects.SHARE, PermissionActions.SHARE_LINKS);
                    }
                    case FieldIdentities.CANSHARELINKEXPIRYREQUIRED: {
                        return this.getPermissionValue(newValues, PermissionObjects.SHARE, PermissionActions.SHARE_LINK_EXPIRY);
                    }
                    case FieldIdentities.DEFAULTSHARELINKEXPIRYTIME: {
                        return this.getPermissionValue(newValues, PermissionObjects.SHARE, PermissionActions.DEFAULT_SHARE_LINK_EXPIRY_TIME, PermissionActions.SHARE_LINK_EXPIRY);
                    }
                    case FieldIdentities.CANSHARELINKPASSWORDREQUIRED: {
                        return this.getPermissionValue(newValues, PermissionObjects.SHARE, PermissionActions.SHARE_LINK_PASSWORD);
                    }
                    case FieldIdentities.DEFAULTSHARELINKPASSWORD: {
                        return this.getPermissionValue(newValues, PermissionObjects.SHARE, PermissionActions.DEFAULT_SHARE_LINK_PASSWORD, PermissionActions.SHARE_LINK_PASSWORD);
                    }
                    case FieldIdentities.CANSHAREFOLDERS: {
                        return this.getPermissionValue(newValues, PermissionObjects.FOLDERS, PermissionActions.SHARE_FOLDERS);
                    }
                    default: {
                        return;
                    }
                }
            }
        } catch (ex) {
            if (ex instanceof TypeError) {
                // We have tried to access an unknown field
                return;
            } else {
                throw (ex);
            }
        }
    }

    public createFields(newValues: Role, disableRoleName: boolean): StandardField[] {
        const createdFields: StandardField[] = [];
        this.seedFields.forEach((field, index) => {
            const props = this.getValueFromField(newValues, field);
            if (props === undefined) {
                // Values aren't available for this field from the new role, skip it.
                return;
            }
            if (field.type === FieldTypes.TEXTBOX) {
                createdFields.push(new Field({
                    controlType: FieldTypes.TEXTBOX,
                    key: field.identity,
                    label: field.label,
                    value: props.value,
                    required: field.required,
                    type: 'text',
                    order: field.order,
                    disabled: index === 0 ? disableRoleName : !props.isEditable,
                    hidden: props.isHidden
                }));
            } else if (field.type === FieldTypes.DROPDOWN) {
                createdFields.push(new Field({
                    controlType: FieldTypes.DROPDOWN,
                    key: field.identity,
                    label: field.label,
                    options: field.options,
                    value: props.value as boolean,
                    required: field.required,
                    order: field.order,
                    disabled: !props.isEditable,
                    hidden: props.isHidden,
                    subFieldContext: field.subFieldsContext,
                    subFields: field.subFields && field.subFields.length ? field.subFields.map((subField, subIndex) => {
                        const subProps = this.getValueFromField(newValues, subField);
                        if (subProps === undefined) {
                            // Values aren't available for this field from the new role, skip it.
                            return;
                        }
                        if (subField.type === FieldTypes.DROPDOWN) {
                            return new Field({
                                controlType: FieldTypes.DROPDOWN,
                                key: subField.identity,
                                label: subField.label,
                                options: subField.options,
                                value: subProps.value as boolean,
                                required: subField.required,
                                order: subField.order,
                                disabled: !subProps.isEditable,
                                hidden: subProps.isHidden,
                                subOf: subField.subOf
                            });
                        } else if (subField.type === FieldTypes.RADIOBUTTON) {
                            return new Field({
                                controlType: FieldTypes.RADIOBUTTON,
                                key: subField.identity,
                                label: subField.label,
                                options: subField.options,
                                value: subProps.value as boolean,
                                required: subField.required,
                                type: 'radio',
                                order: subField.order,
                                disabled: !subProps.isEditable,
                                hidden: subProps.isHidden
                            });
                        }
                    }) : []
                }));
            } else {
                createdFields.push(new Field({
                    key: field.identity,
                    label: field.label,
                    order: field.order,
                    disabled: !props.isEditable,
                    hidden: false
                }));
            }
        });
        return createdFields.sort((a, b) => a.order - b.order);
    }

    private handleError(response: HttpErrorResponse): void {
        if (response.status) {
            switch (response.status) {
                case 422:
                    this.log.error(
                        `error status ${response.status}: ${response.error.message
                        }, detail: ${JSON.stringify(
                            response.error.detail,
                            null,
                            4
                        )}`
                    );
                    break;
                case 429:
                    this.log.error(
                        `error status ${response.status}: ${response.error.message}`
                    );
                    break;
                default:
                    this.log.error(
                        `error status ${response.status}: ${response.error.message
                        }\n detail:${JSON.stringify(
                            response.error.detail,
                            null,
                            4
                        )}`
                    );
            }
        } else {
            this.log.error(JSON.stringify(response));
        }
        throw response;
    }
}
