"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var logger_service_1 = require("../../core/logger.service");
var user_service_1 = require("../../core/user.service");
var validate_service_1 = require("../../core/validate.service");
var dialog_dir_picker_component_1 = require("../../file-list/dialog-dir-picker/dialog-dir-picker.component");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var dir_picker_service_1 = require("../../file-list/dir-picker.service");
var share_invite_service_1 = require("../share-invite.service");
var router_1 = require("@angular/router");
var websocket_service_1 = require("../../core/websocket.service");
var websocket_message_1 = require("../../shared/models/websocket/websocket-message");
var feature_service_1 = require("../../shared/services/feature.service");
var ShareInviteComponent = /** @class */ (function () {
    function ShareInviteComponent(loggerService, userService, fileListService, validateService, modalService, dirPickerService, shareInviteService, route, router, webSocketService, featureService) {
        this.loggerService = loggerService;
        this.userService = userService;
        this.fileListService = fileListService;
        this.validateService = validateService;
        this.modalService = modalService;
        this.dirPickerService = dirPickerService;
        this.shareInviteService = shareInviteService;
        this.route = route;
        this.router = router;
        this.webSocketService = webSocketService;
        this.featureService = featureService;
        this.destinationItem = {
            sync_id: 0,
            name: 'Sync Folder',
        };
        this.spinners = {
            join: false,
            decline: false,
            destination: false,
            invite: false,
        };
        this.chkFileList = [];
        this.isWsProgressAllowed = false;
        this.sharefolder = new forms_1.FormGroup({
            name: new forms_1.FormControl('', [
                forms_1.Validators.required,
                this.leadingSpace.bind(this),
                this.trailingChar.bind(this),
                this.reservedNames.bind(this),
                this.illegalCharacters.bind(this),
                this.exist.bind(this),
            ]),
            displayName: new forms_1.FormControl('', forms_1.Validators.required),
        });
    }
    ShareInviteComponent.prototype.leadingSpace = function (control) {
        var name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return name && name.charAt(0) !== ' ' ? null : { leadingSpace: true };
    };
    ShareInviteComponent.prototype.trailingChar = function (control) {
        var name = control.value;
        var lastChar = name ? name.charAt(name.length - 1) : '';
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return !(lastChar === '.' || lastChar === ' ')
            ? null
            : { trailingChar: true };
    };
    ShareInviteComponent.prototype.reservedNames = function (control) {
        var name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return !/^(CLOCK\$|PRN|AUX|NUL|CON|COM[0-9]|LPT[0-9])$/i.test(name) &&
            name.toLowerCase() !== '.syncinfo' &&
            name.toLowerCase() !== 'thumbs.db' &&
            name.toLowerCase() !== 'desktop.ini' &&
            name.toLowerCase() !== 'sync.cache' &&
            name.toLowerCase() !== '$recycle.bin' &&
            name.indexOf('.~') !== 0 &&
            name.indexOf('~') !== 0 &&
            name.indexOf('~$') === -1 &&
            !/^(PRN\.[a-z0-9]+|AUX\.[a-z0-9]+|NUL\.[a-z0-9]+|CON\.[a-z0-9]+|COM[0-9]\.[a-z0-9]+|LPT[0-9]\.[a-z0-9]+)$/i.test(name)
            ? null
            : { reserved: true };
    };
    ShareInviteComponent.prototype.illegalCharacters = function (control) {
        var name = control.value;
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        return /^[^\/:\*\?"<>\|]+$/.test(name) ? null : { illegal: true };
    };
    ShareInviteComponent.prototype.exist = function (control) {
        var name = control.value;
        if (!this.chkFileList) {
            return true;
        }
        if (this.validateService.isEmpty(name)) {
            return true;
        }
        var found = this.chkFileList.filter(function (val) {
            return val.toLowerCase() == name.toLowerCase();
        });
        return !(found && found.length > 0) ? null : { exist: true };
    };
    ShareInviteComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.errors = [];
                        this.cachekey = this.route.snapshot.params['invite_id'] || this.route.snapshot.queryParams['invite_id'];
                        if (!this.router.url.includes('/shares/rejoin') && this.route.snapshot.queryParams['invite_id']) {
                            this.router.navigate(["/shares/invite/" + this.cachekey]);
                        }
                        this.shareId = parseInt(this.route.snapshot.queryParams['share_id'], 10);
                        this.wrapKey = this.route.snapshot.fragment;
                        if (!this.cachekey) {
                            this.errors.push({
                                code: 1702,
                                msg: 'The url is missing the invite id',
                            });
                        }
                        else {
                            this.initializeInvite();
                        }
                        _a = this;
                        return [4 /*yield*/, this.featureService.isAllowed('websocketProgress')];
                    case 1:
                        _a.isWsProgressAllowed = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteComponent.prototype.ngOnDestroy = function () {
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    };
    ShareInviteComponent.prototype.shareAccept = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.spinners.join = true;
                        if (this.isWsProgressAllowed) {
                            this.progressSubscription = this.webSocketService
                                .getMessageHandler(websocket_message_1.MessageType.SHARE_ACCEPT_PROGRESS)
                                .subscribe(function (progress) {
                                if (progress.share_id === _this.inviteData.shareId) {
                                    _this.progressText = progress.progressPercentage + "%";
                                }
                            });
                        }
                        _a = this;
                        return [4 /*yield*/, this.shareInviteService.accept(this.wrapKey, this.destinationItem.sync_id, this.inviteData)];
                    case 1:
                        _a.shareAcceptJob = _b.sent();
                        this.loggerService.info('share-invite -> share accept job complete');
                        this.spinners.join = false;
                        this.router.navigate(['/shares']);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        this.loggerService.error(err_1);
                        this.loggerService.error('Could not accept the share');
                        this.handleErr({ code: 1612 });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteComponent.prototype.shareDecline = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.spinners.decline = true;
                        return [4 /*yield*/, this.shareInviteService.decline(this.inviteData)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        err_2 = _a.sent();
                        this.loggerService.error(err_2);
                        this.loggerService.error('Could not decline the share');
                        this.handleErr({ code: 1613 });
                        return [3 /*break*/, 4];
                    case 3:
                        this.spinners.decline = false;
                        this.router.navigate(['/shares']);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteComponent.prototype.shareNeedConfirm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.shareInviteService.needConfirm(this.cachekey)];
                    case 1:
                        _a.sent();
                        this.confirmSent = true;
                        this.isLoaded = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteComponent.prototype.getDestination = function () {
        return this.dirPickerService.getDestination();
    };
    ShareInviteComponent.prototype.openDirPicker = function () {
        var _this = this;
        var ref = this.modalService.open(dialog_dir_picker_component_1.DialogDirPickerComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(function (value) {
            // when the dir is chosen, trigger getting new path list and
            // then re-run validation, basically to check if name exists.
            _this.chkFileList = [];
            return _this.getCurrentList(value.sync_id).then(function () {
                _this.destinationItem.sync_id = value.sync_id;
                _this.destinationItem.name = value.name;
                _this.spinners.destination = false;
            });
        });
    };
    ShareInviteComponent.prototype.initializeInvite = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, res, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.spinners.invite = true;
                        this.errors = [];
                        return [4 /*yield*/, this.shareInviteService.getInvite(this.cachekey, this.wrapKey, this.shareId)];
                    case 1:
                        result = _a.sent();
                        this.inviteData = result;
                        this.sharefolder.setValue({
                            name: this.inviteData.name ? this.inviteData.name : '',
                            displayName: this.inviteData.displayName
                                ? this.inviteData.displayName
                                : '',
                        });
                        return [4 /*yield*/, this.getCurrentList(this.userService.get('syncus_sync_id'))];
                    case 2:
                        res = _a.sent();
                        this.spinners.invite = false;
                        this.isLoaded = true;
                        this.missingPassword = false;
                        this.destinationItem.sync_id =
                            this.userService.get('syncus_sync_id');
                        this.destinationItem.name = 'Sync Folder';
                        if (this.inviteData.image_cachekey) {
                            this.isWhiteLabel = true;
                            this.buttonTextColor = this.inviteData.button_text_color;
                            this.buttonPrimaryColor = this.inviteData.button_primary_color;
                        }
                        return [2 /*return*/, res];
                    case 3:
                        err_3 = _a.sent();
                        if (err_3.code == 1621) {
                            if (err_3.msg) {
                                this.missingPasswordLabel = err_3.msg;
                            }
                            this.spinners.invite = false;
                            this.isLoaded = false;
                            this.missingPassword = true;
                        }
                        else {
                            this.handleErr(err_3);
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ShareInviteComponent.prototype.handleErr = function (err) {
        if (typeof err == 'object' && err.code) {
            this.errors.push({
                code: err.code,
                msg: err.msg,
            });
        }
        else if (typeof err == 'object' && err.error_code) {
            this.errors.push({
                code: err.error_code,
            });
        }
        else {
            this.errors.push({ code: 1611 });
        }
    };
    ShareInviteComponent.prototype.getCurrentList = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fileListService.getDataNoState(syncId, '0')];
                    case 1:
                        data = _a.sent();
                        data.pathlist.map(function (cur) {
                            _this.chkFileList.push(cur.name);
                        });
                        return [2 /*return*/, this.chkFileList];
                }
            });
        });
    };
    return ShareInviteComponent;
}());
exports.ShareInviteComponent = ShareInviteComponent;
