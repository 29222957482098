"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var user_service_1 = require("../../core/user.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var link_list_service_1 = require("../services/link-list.service");
var blend_service_1 = require("../../shared/services/blend.service");
var blend_property_names_1 = require("../../util/blend-property-names");
var multiadmin_model_1 = require("../../shared/models/multiadmin.model");
var DialogLinkComponent = /** @class */ (function () {
    function DialogLinkComponent(activeModal, linkList, userService, blendService, linklistService) {
        this.activeModal = activeModal;
        this.linkList = linkList;
        this.userService = userService;
        this.blendService = blendService;
        this.linklistService = linklistService;
        this.initialized = false;
    }
    DialogLinkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.userService.getUser();
        if (!this.item.is_publink) {
            var shareLink = this.userService.checkPermission(models_1.PermissionActions.SHARE_LINKS);
            var shareLinkExpiry = this.userService.checkPermission(models_1.PermissionActions.SHARE_LINK_EXPIRY);
            var shareLinkPassword = this.userService.checkPermission(models_1.PermissionActions.SHARE_LINK_PASSWORD);
            if (shareLink && (shareLinkExpiry || shareLinkPassword)) {
                this.openState = 4;
            }
            else {
                this.openState = 0;
                this.initialized = true;
            }
        }
        else {
            if (this.openState == undefined) {
                this.openState = 1;
            }
            this.linkList
                .getLink(this.item.sync_id, this.item.name)
                .then(function (data) {
                _this.link = data;
                _this.initialized = true;
            });
        }
    };
    DialogLinkComponent.prototype.onStateChange = function (state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((this.openState == 0 || this.openState == 2) && state == 1)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.linkList.getLink(this.item.sync_id, this.item.name)];
                    case 1:
                        data = _a.sent();
                        this.initialized = true;
                        this.link = data;
                        _a.label = 2;
                    case 2:
                        if (this.openState == 4) {
                            this.initialized = true;
                        }
                        this.openState = state;
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogLinkComponent.prototype.onLinkPrecreateOptionsStateChange = function (linkPrecreateOptionsState) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.linkPrecreateOptions = linkPrecreateOptionsState;
                        return [4 /*yield*/, this.onStateChange(0)];
                    case 1:
                        _a.sent();
                        this.blendService.track(models_1.BlendEvent.SAVE_LINK_SETTINGS, blend_property_names_1.renameLinkOptions(tslib_1.__assign({}, this.linkPrecreateOptions, { expiryTimeInDays: this.getExpiryIndays(), isExpiryRequired: true, type: this.item.type, file_extension: this.item.file_extension })));
                        return [2 /*return*/];
                }
            });
        });
    };
    DialogLinkComponent.prototype.getExpiryIndays = function () {
        var _this = this;
        var currDate = new Date();
        var selectedDate = new Date(this.linkPrecreateOptions.exp_servtime);
        var Difference_In_Time = selectedDate.getTime() - currDate.getTime();
        var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        var option = multiadmin_model_1.linkExpiryDateOptions.filter(function (op) { return op.value === _this.linklistService.getLinkEnforcedSettings().linkExpiryDateDefault; });
        return option.length ? Difference_In_Days : 0;
    };
    DialogLinkComponent.prototype.onClose = function (refresh) {
        this.activeModal.close(refresh);
    };
    return DialogLinkComponent;
}());
exports.DialogLinkComponent = DialogLinkComponent;
