"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var notifications_service_1 = require("../../core/notifications.service");
var forms_1 = require("@angular/forms");
var link_list_service_1 = require("../services/link-list.service");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var blend_service_1 = require("../../shared/services/blend.service");
var blend_property_names_1 = require("../../util/blend-property-names");
var store_1 = require("@ngrx/store");
var LinkListActions = require("../../actions/link-list.actions");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var feature_service_1 = require("../../shared/services/feature.service");
var LinkManageComponent = /** @class */ (function () {
    function LinkManageComponent(log, base64, userService, notificationsService, linklistService, formBuilder, blendService, store, api, dirtyOut, featureService) {
        this.log = log;
        this.base64 = base64;
        this.userService = userService;
        this.notificationsService = notificationsService;
        this.linklistService = linklistService;
        this.formBuilder = formBuilder;
        this.blendService = blendService;
        this.store = store;
        this.api = api;
        this.dirtyOut = dirtyOut;
        this.featureService = featureService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.isPro = 0;
        this.linkSendResult = 0;
        this.isOnTrial = 0;
        this.isEnced = true;
        this.linkEnforcedSettings = {
            isLinkExpiryDateRequired: false,
            linkExpiryDateDefault: null,
            isLinkPasswordRequired: false,
            linkPasswordDefault: null
        };
        this.spinner = false;
        this.copyText = 'Copy link';
        this.total = 0;
        this.finished = 0;
        this.isComponentActive = true;
        this.isWsProgressAllowed = false;
    }
    LinkManageComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dt, exp, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dt = new Date();
                        this.expDateMin = {
                            year: dt.getFullYear(),
                            month: dt.getMonth() + 1,
                            day: dt.getDate(),
                        };
                        if (this.link.exp_servtime) {
                            exp = new Date(this.link.exp_servtime);
                            this.expDate = {
                                year: exp.getFullYear(),
                                month: exp.getMonth() + 1,
                                day: exp.getDate(),
                            };
                            this.expTime = {
                                hour: exp.getHours(),
                                minute: exp.getMinutes(),
                                second: 0,
                            };
                        }
                        this.isPro = this.userService.get('is_pro');
                        this.isOnTrial = this.userService.get('is_on_trial');
                        this.isEnced = this.link.cnt > 0 ? false : true;
                        _a = this;
                        return [4 /*yield*/, this.featureService.isAllowed('websocketProgress')];
                    case 1:
                        _a.isWsProgressAllowed = _b.sent();
                        this.linkEnforcedSettings = this.linklistService.getLinkEnforcedSettings();
                        this.linkSettingsForm = this.formBuilder.group({
                            password: [this.link.passwordprotect],
                            expiryDate: [this.expDate],
                            expiryTime: [this.expTime],
                        });
                        if (this.linkEnforcedSettings.isLinkExpiryDateRequired) {
                            this.linkSettingsForm.get('expiryDate').setValidators(forms_1.Validators.required);
                        }
                        if (this.linkEnforcedSettings.isLinkPasswordRequired) {
                            this.linkSettingsForm.get('password').setValidators(forms_1.Validators.required);
                        }
                        if (!this.isEnced) {
                            this.encLink();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.encLink = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updateModalText, data, percentage;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        updateModalText = function (progress) {
                            _this.modalText = _this.isWsProgressAllowed
                                ? "Encrypting link " + progress + "%, Please wait..."
                                : 'Encrypting link, Please wait...';
                        };
                        this.total = this.link.cnt + this.link.enc_cnt;
                        this.finished = this.link.enc_cnt;
                        updateModalText(Math.min(Math.round((this.finished / this.total) * 100), 100));
                        _a.label = 1;
                    case 1:
                        if (!(this.finished < this.total && this.isComponentActive)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dirtyOut.executeForShare(this.link.share_id)];
                    case 2:
                        data = _a.sent();
                        if (!data || (Array.isArray(data) && data.length === 0)) {
                            this.finished = this.total;
                            updateModalText(100);
                        }
                        if (data && data.completed) {
                            this.finished += data.completed;
                            percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                            updateModalText(percentage);
                            if (this.finished >= this.total) {
                                updateModalText(100);
                                return [3 /*break*/, 3];
                            }
                        }
                        return [3 /*break*/, 1];
                    case 3:
                        this.modalText = 'Finalizing Link, Please wait...';
                        this.isEnced = true;
                        this.notificationsService.startNotificationLoop();
                        this.log.info('post link action completed');
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.copy = function (elemId) {
        var _this = this;
        var copyInput = document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        }
        else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(function () {
                _this.copyText = 'Copy link';
            }, 2000);
        }
    };
    LinkManageComponent.prototype.setState = function (state) {
        this.state.emit(state);
    };
    LinkManageComponent.prototype.closeDialog = function () {
        this.close.emit(false);
    };
    LinkManageComponent.prototype.onDateSelection = function () {
        var dt = new Date();
        this.linkSettingsForm.get('expiryTime').setValue({
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: 0,
        });
    };
    LinkManageComponent.prototype.clearExp = function () {
        this.linkSettingsForm.get('expiryDate').setValue(null);
        this.linkSettingsForm.get('expiryTime').setValue(null);
    };
    LinkManageComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expDate, expTime, expServtime, exp, dt, linkSetOptions, password, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.errcode = null;
                        this.spinner = true;
                        expDate = this.linkSettingsForm.get('expiryDate').value;
                        expTime = this.linkSettingsForm.get('expiryTime').value;
                        expServtime = void 0;
                        if (expDate) {
                            if (!expTime) {
                                exp = new Date();
                                expTime = {
                                    hour: exp.getHours(),
                                    minute: exp.getMinutes(),
                                    second: 0,
                                };
                            }
                            dt = new Date(expDate.year, expDate.month - 1, expDate.day, expTime.hour, expTime.minute, expTime.second);
                            expServtime = dt.getTime();
                        }
                        linkSetOptions = {
                            sync_id: this.link.sync_id,
                            share_id: this.link.share_id,
                            publink_id: this.link.cachekey,
                            exp_servtime: parseInt(expServtime, 10),
                            label: this.link.label,
                            download_limit: this.link.download_limit,
                            email_notification: this.link.email_notification,
                            upload: this.link.upload,
                            previewonly: this.link.previewonly,
                            file_edit: this.link.file_edit,
                            compat: this.link.compat,
                            allow_comment: this.link.allow_comment,
                            comment_notification: this.link.comment_notification
                        };
                        return [4 /*yield*/, this.api.execute('linksetoptions', linkSetOptions)];
                    case 1:
                        _a.sent();
                        password = this.base64.encode(this.linkSettingsForm.get('password').value);
                        return [4 /*yield*/, this.api.execute('linksetpassword', {
                                sync_id: this.link.sync_id,
                                share_id: this.link.share_id,
                                publink_id: this.link.cachekey,
                                password: password,
                            })];
                    case 2:
                        _a.sent();
                        // link properties are required to be updated as per new values
                        // so that same and true data is shared between components
                        this.link.exp_servtime = parseInt(expServtime, 10);
                        this.link.password = password;
                        this.link.passwordprotect = this.linkSettingsForm.get('password').value;
                        this.blendService.track(models_1.BlendEvent.SAVE_LINK_SETTINGS, blend_property_names_1.renameLinkOptions(tslib_1.__assign({}, linkSetOptions, { type: this.link.type, file_extension: this.item.file_extension })));
                        this.log.info('Saved link for ' + this.link.cachekey);
                        this.store.dispatch(new LinkListActions.LinkListRefreshAction());
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;
    };
    return LinkManageComponent;
}());
exports.LinkManageComponent = LinkManageComponent;
