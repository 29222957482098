"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var models_1 = require("../shared/models");
exports.renameLinkOptions = function (options) {
    // FILE PERMISSIONS
    var file_permissions;
    if (options['previewonly']) {
        file_permissions = models_1.BlendProperties.LINK_PERMISSION_VIEW;
    }
    if (!options['previewonly'] && !options['file_edit']) {
        file_permissions = models_1.BlendProperties.LINK_PERMISSION_DOWNLOAD;
    }
    if (options['file_edit']) {
        file_permissions = models_1.BlendProperties.LINK_PERMISSION_EDITABLE;
    }
    var isExpiryRequired = options['isExpiryRequired'] ? options['isExpiryRequired'] : false;
    // FILE TYPE
    var type;
    if (options['type'] === 'dir') {
        type = models_1.BlendProperties.LINK_TYPE_FOLDER;
    }
    else {
        type = options['file_extension'];
    }
    // ALLOW COMMENT
    var allow_comment;
    switch (options['allow_comment']) {
        case 1:
            allow_comment = models_1.BlendProperties.LINK_COMMENTS_ENABLED_AUTH;
            break;
        case 2:
            allow_comment = models_1.BlendProperties.LINK_COMMENTS_ENABLED_ALL;
            break;
        default:
            allow_comment = models_1.BlendProperties.LINK_COMMENTS_DISABLED;
    }
    var returnObj = {
        allow_comment: allow_comment,
        comment_notification: !!+options['comment_notification'],
        compat: !!+options['compat'],
        download_limit: options['download_limit'],
        email_notification: !!+options['email_notification'],
        exp_servtime: options['exp_servtime'],
        file_permissions: file_permissions,
        type: type,
        label: options['label'],
        publink_id: options['publink_id'],
        share_id: options['sync_id'],
        sync_id: options['sync_id'],
        upload: !!+options['upload'],
        expiryTimeInDays: options['expiryTimeInDays'],
    };
    if (isExpiryRequired) {
        returnObj = tslib_1.__assign({}, returnObj, { isExpiryRequired: isExpiryRequired });
    }
    if (options['expiryTimeInDays'] && options['expiryTimeInDays'] !== '') {
        returnObj = tslib_1.__assign({}, returnObj, { expiryTimeInDays: options['expiryTimeInDays'] });
    }
    return returnObj;
};
