"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var models_1 = require("../shared/models");
var store_1 = require("@ngrx/store");
var fromRoot = require("../reducers");
var AuthActions = require("../actions/auth.actions");
var api_service_1 = require("./api.service");
var logger_service_1 = require("./logger.service");
var UserService = /** @class */ (function () {
    function UserService(api, store, log) {
        this.api = api;
        this.store = store;
        this.log = log;
        this.MAX_USERS = 299;
        this.MIN_USERS = 3;
        this.initialized = false;
        this.authenticated = false;
        this.DEFAULT_ROLE = 'Default User';
        this.init();
    }
    UserService.prototype.init = function () {
        var _this = this;
        this.store
            .select(fromRoot.getAuthState)
            .subscribe(function (data) {
            _this.authenticated = data.authenticated;
            if (!data.authenticated) {
                _this.user = new models_1.User();
                _this.initialized = false;
                return;
            }
            if (data.user) {
                _this.user = data.user;
                _this.initialized = true;
            }
        });
    };
    // ----- ng1 functions
    UserService.prototype.get = function (key) {
        return this.user[key];
    };
    UserService.prototype.set = function (key, val) {
        console.error('UserService.set() ERROR SETTING ' + key + ' = ' + val);
    };
    UserService.prototype.isAuthenticated = function () {
        return this.authenticated;
    };
    UserService.prototype.checkPermission = function (permName) {
        if (this.user.is_multi_new) {
            // CNC users
            var val = this.user.permissions[permName];
            if (typeof val === 'number') {
                return true;
                // return default true because the value is of type number
                // which means the prop is enforced
                // if boolean, it is explicit
            }
            return this.user.permissions[permName];
        }
        else if ((this.user.zuora_acct_key === '' || this.user.zuora_acct_key === null) && !this.user.is_multi_child && !this.user.is_multi_admin) {
            // free users
            if (permName === 'viewBilling' || permName === 'viewUsers' || permName === 'viewRoles' || permName === 'shareLinkExpiry' || permName === 'shareLinkPassword') {
                return false;
            }
            return true;
        }
        else if (!this.user.is_multi_child) {
            // paid users other than teams+ will have these false as default
            if (permName === 'shareLinkExpiry' || permName === 'shareLinkPassword') {
                return false;
            }
            if (!this.user.is_multi_admin) {
                // solo or personal paid users
                // if (permName === 'viewUsers') return false;
                return this.user.zuora_acct_key !== '' && this.user.zuora_acct_key !== null;
            }
            // legacy business teams users
            // return (this.user.zuora_acct_key !== "" && this.user.zuora_acct_key !== null) && this.user.is_multi_admin;
            // for trial users no need to check for zuora accnt key
            if (permName === 'viewUsers') {
                return this.user.is_multi_admin;
            }
            if (permName === 'viewBilling' || permName === 'manageBilling') {
                return (this.user.zuora_acct_key !== '' && this.user.zuora_acct_key !== null) && this.user.is_multi_admin;
            }
        }
        return false;
    };
    UserService.prototype.getSubPermissionValues = function (permName) {
        return this.user.permissions[permName];
    };
    UserService.prototype.getPermssionByKey = function (permissionKey) {
        return this.user.is_multi_new == true ? this.user['permissions'][permissionKey] : true;
    };
    UserService.prototype.clear = function () {
        console.error('UserService.clear() not implemented');
    };
    // -- end ng1 functions
    UserService.prototype.subscribeUser = function () {
    };
    UserService.prototype.getUser = function () {
        if (!this.initialized) {
            console.log('Not initialized yet');
            this.init();
        }
        return this.user;
    };
    UserService.prototype.getStarted = function () {
        var getstarted = {
            hasVerified: this.user.is_verified,
            hasPasswordHint: this.user.has_password_hint,
            hasMobile: this.user.install_mobile,
            hasDeviceOne: this.user.install_desktop,
            hasDeviceMany: this.user.install_desktop > 1,
            hasFiles: this.user.has_files,
            hasShares: this.user.has_shares,
            hasLinks: this.user.has_links,
            getstarted_completed: 0
        };
        return getstarted;
    };
    /**
     * Initiates a refresh request for the user.  This will trigger the
     * subscription to trigger.
     */
    UserService.prototype.refresh = function () {
        this.lastCheckTime = Date.now();
        this.store.dispatch(new AuthActions.RefreshAction());
    };
    /**
     * Updates every 5 minutes, used for any polling.
     */
    UserService.prototype.update = function () {
        var timeDiff = Date.now() - this.lastCheckTime;
        if (timeDiff > 60 * 5 * 1000) {
            this.reload();
        }
    };
    /**
     * Initiates the network call to get the actual user information.  The
     * auth.effects and reducer will take care of updating the state of the
     * user object.
     */
    UserService.prototype.reload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.user.exists === 1)) return [3 /*break*/, 2];
                        this.lastCheckTime = Date.now();
                        return [4 /*yield*/, this.api.execute('userget', {})];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.dateConversionForTrials = function (termEndDate, trimOneDay) {
        var dateArr = termEndDate.split('-');
        if (dateArr.length !== 3) {
            this.log.error("An invalid term end date received " + termEndDate);
        }
        var d = parseInt(dateArr[2], 10) - (trimOneDay ? 1 : 0), 
        // because the data that we have here is related to subscription and not the actual plan term
        // as per zuora, if auto renew is enabled, the subscription current term end and new renewal
        // happens on same day, even though the plan term ends a day before
        m = parseInt(dateArr[1], 10) - 1, 
        // no need to trim month as we are using date string
        y = parseInt(dateArr[0], 10);
        return new Date(y, m, d);
        // zuora date format changed from yyyy-mm-dd to mm-dd-yyyy so that when converted to user's local time zone,
        // the utc time will be considered and time zone issue will be resolved.
        // This time zone fix is being applied only for trial users
    };
    return UserService;
}());
exports.UserService = UserService;
