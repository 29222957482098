"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var user_service_1 = require("../../core/user.service");
var forms_1 = require("@angular/forms");
var file_item_service_1 = require("../../file/file-item.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var link_list_service_1 = require("../services/link-list.service");
var LinkPrecreateSettingsComponent = /** @class */ (function () {
    function LinkPrecreateSettingsComponent(userService, formBuilder, fileItemService, base64, log, linklistService) {
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.fileItemService = fileItemService;
        this.base64 = base64;
        this.log = log;
        this.linklistService = linklistService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.linkPrecreateOptionsState = new core_1.EventEmitter();
        this.submitted = false;
        this.isMSOfficeFile = false;
        this.linkEnforcedSettings = {
            isLinkExpiryDateRequired: false,
            linkExpiryDateDefault: null,
            isLinkPasswordRequired: false,
            linkPasswordDefault: null
        };
        this.isPro = false;
    }
    LinkPrecreateSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var dt = new Date();
        this.expDateMin = {
            year: dt.getFullYear(),
            month: dt.getMonth() + 1,
            day: dt.getDate(),
        };
        this.isPro = this.userService.get('is_pro');
        this.isMSOfficeFile = this.fileItemService.isMSOffice(this.item, 'edit');
        this.linkEnforcedSettings = this.linklistService.getLinkEnforcedSettings();
        this.linkPreSettingsForm = this.formBuilder.group({
            password: [''],
            expiryDate: [null],
            expiryTime: [null],
            upload: ['0', forms_1.Validators.required],
            previewonly: [
                'download',
                forms_1.Validators.required,
            ],
        });
        this.linkPreSettingsForm.controls.upload.valueChanges.subscribe(function (uploadFieldValue) {
            // file permission radio buttons are disbled according to the upload radio values
            // reset previewonly field value when all file permission radio buttons are disabled
            // or when the edit file radio button is disabled
            if (_this.item.type === 'dir') {
                if (uploadFieldValue === '2' || (uploadFieldValue === '0' && _this.linkPreSettingsForm.value.previewonly === 'edit')) {
                    _this.linkPreSettingsForm.controls.previewonly.setValue('download');
                }
            }
        });
        if (this.linkEnforcedSettings.isLinkExpiryDateRequired) {
            this.linkPreSettingsForm.get('expiryDate').setValidators(forms_1.Validators.required);
        }
        if (this.linkEnforcedSettings.linkExpiryDateDefault) {
            var today = new Date();
            var currDt = new Date(new Date().setDate(today.getDate() + this.linkEnforcedSettings.linkExpiryDateDefault)); // adjusting according to default value
            this.linkPreSettingsForm.get('expiryDate').setValue({
                year: currDt.getFullYear(),
                month: currDt.getMonth() + 1,
                day: currDt.getDate()
            });
            this.onDateSelection();
        }
        if (this.linkEnforcedSettings.isLinkPasswordRequired) {
            this.linkPreSettingsForm.get('password').setValidators(forms_1.Validators.required);
        }
    };
    LinkPrecreateSettingsComponent.prototype.onDateSelection = function () {
        var dt = new Date();
        this.linkPreSettingsForm.get('expiryTime').setValue({
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: 0,
        });
    };
    LinkPrecreateSettingsComponent.prototype.clearExp = function () {
        this.linkPreSettingsForm.get('expiryDate').setValue(null);
        this.linkPreSettingsForm.get('expiryTime').setValue(null);
    };
    LinkPrecreateSettingsComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expDate, expTime, expServtime, exp, dt, uploadVal;
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.errcode = null;
                    expDate = this.linkPreSettingsForm.get('expiryDate').value;
                    expTime = this.linkPreSettingsForm.get('expiryTime').value;
                    expServtime = void 0;
                    if (expDate) {
                        if (!expTime) {
                            exp = new Date();
                            expTime = {
                                hour: exp.getHours(),
                                minute: exp.getMinutes(),
                                second: 0,
                            };
                        }
                        dt = new Date(expDate.year, expDate.month - 1, expDate.day, expTime.hour, expTime.minute, expTime.second);
                        expServtime = dt.getTime();
                    }
                    uploadVal = parseInt(this.linkPreSettingsForm.get('upload').value, 10);
                    if (this.item.type === 'file') {
                        uploadVal = this.linkPreSettingsForm.value.previewonly === 'edit' ? 1 : 0;
                    }
                    this.linkPrecreateOptionsState.emit({
                        exp_servtime: parseInt(expServtime, 10),
                        // if preview only option is selected from file permissions, set previewonly in link options
                        previewonly: this.linkPreSettingsForm.value.previewonly === 'preview' ? 1 : 0,
                        // if edit option is selected from file permissions, set file_edit in link options
                        // this will set the lnkFILEEDIT in LinkFeatures
                        file_edit: this.linkPreSettingsForm.value.previewonly === 'edit' ? 1 : 0,
                        password: this.base64.encode(this.linkPreSettingsForm.get('password').value),
                        upload: uploadVal,
                    });
                }
                catch (ex) {
                    this.errcode = models_1.ErrCode.fromException(ex);
                }
                return [2 /*return*/];
            });
        });
    };
    LinkPrecreateSettingsComponent.prototype.onState = function (state) {
        this.state.emit(state);
    };
    return LinkPrecreateSettingsComponent;
}());
exports.LinkPrecreateSettingsComponent = LinkPrecreateSettingsComponent;
