"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var rxjs_1 = require("rxjs");
var webSocket_1 = require("rxjs/webSocket");
var operators_1 = require("rxjs/operators");
var store_1 = require("@ngrx/store");
var fromRoot = require("../reducers");
var models_1 = require("../shared/models");
var sync_crypt_service_1 = require("./crypt/sync-crypt.service");
var msgpack = require("msgpack-lite");
var logger_service_1 = require("./logger.service");
var websocket_message_1 = require("../shared/models/websocket/websocket-message");
var url_service_1 = require("./url.service");
var i0 = require("@angular/core");
var i1 = require("@ngrx/store");
var i2 = require("./crypt/sync-crypt.service");
var i3 = require("./logger.service");
var i4 = require("./url.service");
var WebSocketService = /** @class */ (function () {
    function WebSocketService(store, crypt, loggerService, urlService) {
        var _this = this;
        this.store = store;
        this.crypt = crypt;
        this.loggerService = loggerService;
        this.urlService = urlService;
        this.maxReconnectAttempts = 2;
        this.currentServerIndex = 0;
        this.retryDelay = 1000;
        // General subject to handle dynamic message broadcasting
        this.messageSubject = new rxjs_1.Subject();
        // Exposed observable for components to subscribe to all messages
        this.messages$ = this.messageSubject.asObservable();
        // Map to hold handlers for different message types
        this.messageHandlers = new Map();
        this.store.pipe(store_1.select(fromRoot.getAuthUser)).subscribe(function (data) {
            _this.user = data;
        });
        // Initialize handlers for different MessageTypes
        this.messageHandlers.set(websocket_message_1.MessageType.LINK_NEW_PROGRESS, new rxjs_1.Subject());
        this.messageHandlers.set(websocket_message_1.MessageType.UPDATE_USER, new rxjs_1.Subject());
        // Additional handlers can be added here as needed
    }
    WebSocketService.prototype.connect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var serverUrl, params, inputHeaders, webSocketUrl, authString, authHex;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.connectionPool = this.urlService.getHostByType('news');
                        if (this.connectionPool.length === 0) {
                            this.loggerService.warn('No websocket connection pool found');
                            return [2 /*return*/];
                        }
                        if (this.subject && !this.subject.closed) {
                            return [2 /*return*/]; // If connection already exists and is open, do not create a new one
                        }
                        serverUrl = this.connectionPool[this.currentServerIndex];
                        params = new models_1.BaseApiInput();
                        return [4 /*yield*/, this.crypt.signApiReq(params)];
                    case 1:
                        inputHeaders = _a.sent();
                        webSocketUrl = serverUrl
                            + '/multisub'
                            + ("/cpanel" + this.user.uid);
                        authString = inputHeaders.access_token + "," + inputHeaders.servtime + "," + inputHeaders.signature;
                        authHex = this.crypt.bytesToHex(new TextEncoder().encode(authString));
                        this.subject = webSocket_1.webSocket({
                            url: webSocketUrl,
                            binaryType: 'arraybuffer',
                            protocol: ['ws+meta.nchan', "Auth" + authHex],
                            deserializer: function (e) {
                                try {
                                    _this.loggerService.info('WebSocket message deserializer:: ');
                                    _this.loggerService.info(e);
                                    // Helper function to parse metadata from text
                                    var parseMetadata = function (text) {
                                        return text.split('\n').reduce(function (meta, line) {
                                            var _a = line.split(': '), key = _a[0], value = _a[1];
                                            if (key && value) {
                                                meta[key.trim()] = value.trim();
                                            }
                                            return meta;
                                        }, {});
                                    };
                                    // Convert data to a string depending on its type
                                    var data = typeof e.data === 'string'
                                        ? e.data // Handle text/plain payload
                                        : new TextDecoder().decode(new Uint8Array(e.data)); // Handle application/octet-stream payload
                                    // Find the boundary between metadata and the message body
                                    var splitIndex = data.indexOf('\n\n');
                                    if (splitIndex === -1) {
                                        _this.loggerService.error('Invalid message format: No metadata delimiter found');
                                        return null;
                                    }
                                    // Parse and log metadata
                                    var metadata = parseMetadata(data.substring(0, splitIndex));
                                    _this.loggerService.info('Message Metadata:: ');
                                    _this.loggerService.info(metadata);
                                    // Extract the message body
                                    var messageData = data.substring(splitIndex + 2);
                                    // Handle different payload types based on the original data type
                                    if (typeof e.data === 'string') {
                                        // Payload is a JSON-encoded string
                                        try {
                                            var jsonMessage = JSON.parse(messageData);
                                            _this.loggerService.info('Message Data:: ');
                                            _this.loggerService.info(jsonMessage);
                                            return jsonMessage;
                                        }
                                        catch (jsonError) {
                                            _this.loggerService.error('Error parsing JSON message data:: ');
                                            _this.loggerService.error(jsonError);
                                            return null;
                                        }
                                    }
                                    else {
                                        // Payload is MessagePack-encoded binary data
                                        try {
                                            var messageBuffer = new Uint8Array(e.data, splitIndex + 2);
                                            var decodedMessage = msgpack.decode(messageBuffer);
                                            _this.loggerService.info('Decoded Message:: ');
                                            _this.loggerService.info(decodedMessage);
                                            return decodedMessage;
                                        }
                                        catch (decodeError) {
                                            _this.loggerService.error('Error decoding MessagePack data:: ');
                                            _this.loggerService.error(decodeError);
                                            return null;
                                        }
                                    }
                                }
                                catch (err) {
                                    _this.loggerService.error('Error deserializing websocket payload:: ');
                                    _this.loggerService.error(err);
                                    return null;
                                }
                            }
                        });
                        this.connection$ = this.subject.pipe(operators_1.retryWhen(function (errors) {
                            return errors.pipe(operators_1.delayWhen(function () { return rxjs_1.timer(_this.retryDelay); }), operators_1.tap(function () { return _this.loggerService.info("Retry attempt on server: " + serverUrl); }), operators_1.switchMap(function (error, index) {
                                _this.loggerService.error('Error connecting websocket');
                                _this.loggerService.error(error);
                                if (index + 1 >= _this.maxReconnectAttempts) {
                                    _this.switchToNextServer();
                                    return rxjs_1.EMPTY;
                                }
                                else {
                                    return rxjs_1.timer(_this.retryDelay);
                                }
                            }));
                        }), operators_1.catchError(function (err) {
                            _this.loggerService.error('WebSocket error:: ');
                            _this.loggerService.error(err);
                            _this.switchToNextServer();
                            return rxjs_1.EMPTY;
                        }));
                        this.connection$.subscribe(function (msg) {
                            try {
                                _this.loggerService.info('WebSocket message received:: ');
                                _this.loggerService.info(msg);
                                // Broadcast the message to the general subject
                                _this.messageSubject.next(msg);
                                // Route to the appropriate handler based on MessageType
                                var handler = _this.messageHandlers.get(msg.type);
                                if (handler) {
                                    handler.next(msg.payload);
                                }
                                else {
                                    _this.loggerService.warn("No handler found for message type: " + msg.type);
                                }
                            }
                            catch (err) {
                                _this.loggerService.error('Error processing message:: ');
                                _this.loggerService.error(err);
                            }
                        }, function (err) {
                            _this.loggerService.error('Connection error:: ');
                            _this.loggerService.error(err);
                            _this.switchToNextServer();
                        }, function () {
                            _this.loggerService.error('Connection closed');
                            _this.switchToNextServer();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    WebSocketService.prototype.getMessageHandler = function (type) {
        var handler = this.messageHandlers.get(type);
        if (!handler) {
            var newHandler = new rxjs_1.Subject();
            this.messageHandlers.set(type, newHandler);
            return newHandler.asObservable();
        }
        return handler.asObservable();
    };
    WebSocketService.prototype.switchToNextServer = function () {
        this.clearSubject();
        this.currentServerIndex = (this.currentServerIndex + 1) % this.connectionPool.length;
        this.connect();
    };
    WebSocketService.prototype.clearSubject = function () {
        if (this.subject) {
            this.subject.complete();
            this.subject = null; // Nullify the subject to allow reconnection
        }
    };
    WebSocketService.prototype.close = function () {
        this.clearSubject();
    };
    WebSocketService.ngInjectableDef = i0.defineInjectable({ factory: function WebSocketService_Factory() { return new WebSocketService(i0.inject(i1.Store), i0.inject(i2.SyncCryptService), i0.inject(i3.LoggerService), i0.inject(i4.UrlService)); }, token: WebSocketService, providedIn: "root" });
    return WebSocketService;
}());
exports.WebSocketService = WebSocketService;
