"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BlendEvent;
(function (BlendEvent) {
    BlendEvent["SIGN_IN"] = "SignIn";
    BlendEvent["IDENTIFY"] = "$identify";
    BlendEvent["BUTTON_CLICKED"] = "button_clicked";
    BlendEvent["DELETE_ACCOUNT"] = "Delete Account";
    BlendEvent["CONTACT_SUPPORT"] = "Contact Support";
    BlendEvent["HELP_EVENT"] = "Help Event";
    BlendEvent["GET_HELP_EVENT"] = "Get Help Event";
    BlendEvent["CANCEL_HELP"] = "Cancel Help";
    BlendEvent["ADD_FAVORITE_TAG"] = "Add Favorite Tag";
    BlendEvent["REMOVE_FAVORITE_TAG"] = "Remove Favorite Tag";
    BlendEvent["VIEW_FAVORITE_LIST"] = "View Favorite List";
    BlendEvent["SYNC_KNOWLEDGEBASE"] = "Sync Knowledgebase";
    BlendEvent["UPLOAD_FILE"] = "Upload File";
    BlendEvent["CREATE_FILE"] = "Create File";
    BlendEvent["DOWNLOAD_FILE"] = "Download File";
    BlendEvent["DOWNLOAD_AS_ZIP"] = "Download As Zip";
    BlendEvent["VIEW_FILE"] = "View File";
    BlendEvent["EDIT_FILE_OFFICE"] = "Edit File Office";
    BlendEvent["SHARE_LINK"] = "Share Link";
    BlendEvent["SHARED_FOLDER_CREATED"] = "shared_folder_created";
    BlendEvent["SAVE_LINK_SETTINGS"] = "save_link_settings";
    BlendEvent["UPGRADE_INQUIRY"] = "Upgrade Inquiry";
    BlendEvent["ANALYTICS_OPT_IN"] = "Opt In";
    BlendEvent["ANALYTICS_OPT_OUT"] = "Opt Out";
    BlendEvent["NEW_UI_OPT_IN"] = "New UI Opt In";
    BlendEvent["NEW_UI_OPT_OUT"] = "New UI Opt Out";
    BlendEvent["SIGN_UP"] = "SignUp";
    BlendEvent["SUB_USER_ADDED"] = "sub_user_added";
    BlendEvent["UPLOAD"] = "upload";
    BlendEvent["DELETE_ITEM"] = "delete_item";
    BlendEvent["MOVE_ITEMS"] = "move_items";
    BlendEvent["RETRY_UPLOAD_BUTTON_DISPLAYED"] = "retry_upload_button_displayed";
    BlendEvent["CHANGE_ACCOUNT_SETTING"] = "change_account_setting";
})(BlendEvent = exports.BlendEvent || (exports.BlendEvent = {}));
var BlendProperties;
(function (BlendProperties) {
    BlendProperties["LINK_TYPE_FOLDER"] = "folder";
    BlendProperties["LINK_PERMISSION_VIEW"] = "view only";
    BlendProperties["LINK_PERMISSION_DOWNLOAD"] = "view & download";
    BlendProperties["LINK_PERMISSION_EDITABLE"] = "view, downlaod & edit";
    BlendProperties["LINK_COMMENTS_DISABLED"] = "disabled";
    BlendProperties["LINK_COMMENTS_ENABLED_AUTH"] = "enabled for authenticated users";
    BlendProperties["LINK_COMMENTS_ENABLED_ALL"] = "enabled for all users";
})(BlendProperties = exports.BlendProperties || (exports.BlendProperties = {}));
var PermissionActions;
(function (PermissionActions) {
    PermissionActions["CREATED_AT"] = "created_at";
    PermissionActions["UPDATED_AT"] = "updated_at";
    PermissionActions["MANAGE_USERS"] = "manageUsers";
    PermissionActions["VIEW_USERS"] = "viewUsers";
    PermissionActions["ADD_EDIT_USERS"] = "addEditUsers";
    PermissionActions["MANAGE_ROLES"] = "manageRoles";
    PermissionActions["VIEW_ROLES"] = "viewRoles";
    PermissionActions["ADD_EDIT_ROLES"] = "addEditRoles";
    PermissionActions["VIEW_BILLING"] = "viewBilling";
    PermissionActions["MANAGE_BILLING"] = "manageBilling";
    PermissionActions["PURGE_FILES"] = "purgeFiles";
    PermissionActions["FORCE_2FA_ON_FIRST_LOGIN"] = "force2faOnFirstLogin";
    PermissionActions["SHARE_LINKS"] = "sharelinks";
    PermissionActions["SHARE_LINK_EXPIRY"] = "shareLinkExpiry";
    PermissionActions["DEFAULT_SHARE_LINK_EXPIRY_TIME"] = "shareLinkExpiry";
    PermissionActions["SHARE_LINK_PASSWORD"] = "shareLinkPassword";
    PermissionActions["DEFAULT_SHARE_LINK_PASSWORD"] = "shareLinkPassword";
    PermissionActions["SHARE_FOLDERS"] = "shareFolders";
})(PermissionActions = exports.PermissionActions || (exports.PermissionActions = {}));
var PlanType;
(function (PlanType) {
    PlanType["bpt"] = "business-teams";
    PlanType["per"] = "personal";
    PlanType["lper"] = "personal-legacy";
    PlanType["ps"] = "business-solo";
    PlanType["psp"] = "pro-solo-pro";
    PlanType["bptu"] = "business-teams-advanced";
    PlanType["bptsp"] = "business-teams-plus";
    PlanType["bptup"] = "business-teams-advanced-plus";
    PlanType["free"] = "free";
})(PlanType = exports.PlanType || (exports.PlanType = {}));
var WebpathActionType;
(function (WebpathActionType) {
    WebpathActionType["COPY"] = "Copy";
    WebpathActionType["MOVE"] = "Move";
})(WebpathActionType = exports.WebpathActionType || (exports.WebpathActionType = {}));
var FieldTypes;
(function (FieldTypes) {
    FieldTypes["TEXTBOX"] = "textbox";
    FieldTypes["DROPDOWN"] = "dropdown";
    FieldTypes["RADIOBUTTON"] = "radiobutton";
})(FieldTypes = exports.FieldTypes || (exports.FieldTypes = {}));
var FieldIdentities;
(function (FieldIdentities) {
    FieldIdentities["ROLENAME"] = "roleName";
    FieldIdentities["CANPURGEFILES"] = "canPurgeFiles";
    FieldIdentities["CANMANAGEUSERS"] = "canManageUsers";
    FieldIdentities["CANVIEWUSERS"] = "canViewUsers";
    FieldIdentities["CANADDEDITUSERS"] = "canAddEditUsers";
    FieldIdentities["CANMANAGEROLES"] = "canManageRoles";
    FieldIdentities["CANVIEWROLES"] = "canViewRoles";
    FieldIdentities["CANADDEDITROLES"] = "canAddEitRoles";
    FieldIdentities["CANVIEWBILLING"] = "canViewBilling";
    FieldIdentities["CANMANAGEBILLING"] = "canManageBilling";
    FieldIdentities["CANFORCE2FALOGIN"] = "canForce2faLogin";
    FieldIdentities["CANSHARELINKS"] = "CanSharelinks";
    FieldIdentities["CANSHARELINKEXPIRYREQUIRED"] = "CanShareLinkExpiryRequired";
    FieldIdentities["DEFAULTSHARELINKEXPIRYTIME"] = "DefaultShareLinkExpiryTime";
    FieldIdentities["CANSHARELINKPASSWORDREQUIRED"] = "CanShareLinkPasswordRequired";
    FieldIdentities["DEFAULTSHARELINKPASSWORD"] = "DefaultShareLinkPassword";
    FieldIdentities["CANSHAREFOLDERS"] = "canShareFolders";
})(FieldIdentities = exports.FieldIdentities || (exports.FieldIdentities = {}));
var ModalCloseStates;
(function (ModalCloseStates) {
    ModalCloseStates["USER_ROLE_UPDATED"] = "userRoleUpdated";
    ModalCloseStates["REMOVED"] = "removed";
    ModalCloseStates["PASSWORD_UPDATED"] = "passwordUpdated";
})(ModalCloseStates = exports.ModalCloseStates || (exports.ModalCloseStates = {}));
var PermissionObjects;
(function (PermissionObjects) {
    PermissionObjects["METADATA"] = "_metadata";
    PermissionObjects["ADMIN"] = "admin";
    PermissionObjects["BILLING"] = "billing";
    PermissionObjects["FILES"] = "files";
    PermissionObjects["SETTINGS"] = "settings";
    PermissionObjects["SHARE"] = "share";
    PermissionObjects["FOLDERS"] = "folders";
})(PermissionObjects = exports.PermissionObjects || (exports.PermissionObjects = {}));
