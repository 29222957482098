"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var file_list_service_1 = require("../services/file-list.service");
var models_1 = require("../../shared/models");
var file_selection_service_1 = require("../services/file-selection.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var thumbnails_service_1 = require("../services/thumbnails.service");
var dispatch_service_1 = require("../../core/dispatch.service");
var share_service_1 = require("../../shares/share.service");
var platform_browser_1 = require("@angular/platform-browser");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var dialog_share_app_list_component_1 = require("../../shares/dialog-share-app-list/dialog-share-app-list.component");
var dialog_create_blank_document_component_1 = require("../dialog-create-blank-document/dialog-create-blank-document.component");
var zuora_account_service_1 = require("../../account/services/zuora-account.service");
var store_1 = require("@ngrx/store");
var fromRoot = require("../../reducers");
var build_transfer_item_service_1 = require("../../transfer/services/build-transfer-item.service");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var router_1 = require("@angular/router");
var run_upload_folder_service_1 = require("src/app/transfer/services/run-upload-folder.service");
var upload_folder_service_1 = require("src/app/transfer/upload-folder.service");
var file_item_service_1 = require("src/app/file/file-item.service");
var feedback_service_1 = require("src/app/notifications/feedback.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var sku_service_1 = require("../../shared/services/sku.service");
var starred_list_service_1 = require("../services/starred-list.service");
var FileListComponent = /** @class */ (function () {
    function FileListComponent(broadcastService, dispatchService, fileListService, fileSelectionService, loggerService, thumbnailsService, buildTransferItemService, userService, modalService, shareService, _sanitizer, zuora, store, route, router, runUploadFolderService, uploadFolderService, fileItemService, feedbackService, blendService, skuService, starredListService) {
        this.broadcastService = broadcastService;
        this.dispatchService = dispatchService;
        this.fileListService = fileListService;
        this.fileSelectionService = fileSelectionService;
        this.loggerService = loggerService;
        this.thumbnailsService = thumbnailsService;
        this.buildTransferItemService = buildTransferItemService;
        this.userService = userService;
        this.modalService = modalService;
        this.shareService = shareService;
        this._sanitizer = _sanitizer;
        this.zuora = zuora;
        this.store = store;
        this.route = route;
        this.router = router;
        this.runUploadFolderService = runUploadFolderService;
        this.uploadFolderService = uploadFolderService;
        this.fileItemService = fileItemService;
        this.feedbackService = feedbackService;
        this.blendService = blendService;
        this.skuService = skuService;
        this.starredListService = starredListService;
        this.curDate = new Date();
        this.isLoaded = false;
        this.isReadOnly = 0;
        this.predicate = 'default';
        this.paginate = {
            total: 0,
            currentPage: 0,
            itemsPerPage: 100,
            numPages: 0,
            viewOffset: 0,
            viewEnd: 0,
        };
        this.filter = '';
        this.showfavorites = false;
        this.page = 1;
        this.loadingProgress = 0;
        this.showLoading = false;
        this.showFilter = false;
        this.showGrid = false;
        this.showFileMenu = false;
        this.showGetstarted = false;
        this.showRightMenu = false;
        this.hasAppsNewFeature = false;
        this.showInfo = false;
        this.showFreeTrialReminder = false;
        this.showPayNowBanner = false;
        this.permissionActions = models_1.PermissionActions;
        this.total_favorite = 0;
        this.is_tagEnabled = true;
    }
    FileListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.saveRouterStrategyReuseLogic = this.router.routeReuseStrategy.shouldReuseRoute;
                        this.router.routeReuseStrategy.shouldReuseRoute = function (future, curr) { return false; };
                        this.router.events.subscribe(function (event) {
                            if (event instanceof router_1.NavigationEnd) {
                                // Check if it's a back navigation
                                if (_this.isBackNavigation()) {
                                    // Reload your component or perform any necessary actions
                                    _this.reloadComponent();
                                }
                            }
                        });
                        if (this.route.snapshot.queryParams['sync_id']) {
                            this.router.navigate(['/files']);
                        }
                        this.userSubscription = this.store
                            .pipe(store_1.select(fromRoot.getAuthUser))
                            .subscribe(function (data) {
                            _this.user = data;
                        });
                        this.route.data.subscribe(function (data) {
                            if (data.defaultSyncIdKey) {
                                _this.defaultSyncId = _this.userService.get(data.defaultSyncIdKey);
                            }
                        });
                        // console.warn('FILE LIST CONTROLLER');
                        this.showGetstarted = !this.userService.get('has_gettingstarted');
                        this.transferView = this.buildTransferItemService.view;
                        this.folderTransferView = this.runUploadFolderService.view;
                        this.broadcastService.on('event:hide.tools').subscribe(function () {
                            _this.showNewFolder = false;
                            _this.showFilter = false;
                            _this.selectAll(false);
                        });
                        this.broadcastService.on('event:keydown:45').subscribe(function () { return (_this.showNewFolder = true); });
                        this.eventSubscription = this.broadcastService.on('event:file-list.reload').subscribe(function (params) { return _this.reload(params); });
                        this.broadcastService.on('event:file-list.rename').subscribe(function (params) { return _this.setRenameState(params); });
                        this.histId = -1;
                        this.isVault = this.router.url.includes('/vault') ? true : false;
                        this.is_starred = this.router.url.includes('/starred') ? true : false;
                        this.favSpinner = this.is_starred ? true : false;
                        this.fileListService.getCwdSubscription().subscribe(function (data) {
                            if (data) {
                                _this.cwd = data;
                            }
                        });
                        this.isRoot = this.syncId == this.userService.get('syncus_sync_id') || this.syncId == this.userService.get('web_sync_id');
                        this.subscription = this.fileListService
                            .getListSubscription()
                            .subscribe(function (data) {
                            // console.log('======================');
                            // console.log('file list subscription');
                            // console.log(data);
                            // @TODO once this is ported to ng6, most of the subscription can be
                            // tightened up to select specific areas to prevent excessive loading
                            if (data.cwd) {
                                _this.cwd = data.cwd;
                                _this.isReadOnly = _this.cwd.is_readonly;
                                _this.isRoot = data.cwd.sync_id == _this.defaultSyncId;
                                _this.syncId = _this.cwd.sync_id;
                                // if (data.cwd.name === 'Camera Upload' && data.cwd.pid == this.defaultSyncId) {
                                //     this.predicateReverse = false;
                                //     this.setPredicate('usertime');
                                // }
                            }
                            if (data.parents) {
                                _this.parents = data.parents;
                            }
                            if (data.progress > 0 && data.progress < 92) {
                                _this.showLoading = true;
                            }
                            else if (data.progress >= 100) {
                                _this.showLoading = false;
                            }
                            else {
                                _this.showLoading = false;
                            }
                            _this.loadingProgress = data.progress;
                            if (data.error) {
                                _this.errcode = data.error;
                            }
                            if (data.loaded && data.sorted) {
                                _this.isRoot = _this.syncId == _this.userService.get('syncus_sync_id') || _this.syncId == _this.userService.get('web_sync_id');
                                _this.total_favorite = data.total_favorite;
                                if (data.pathlist.length && _this.shouldLoadList(data)) {
                                    if (data.showdeleted) {
                                        _this.list = data.pathlist.filter(function (item) {
                                            if (item.is_deleted) {
                                                return true;
                                            }
                                        });
                                    }
                                    else {
                                        _this.list = data.pathlist;
                                    }
                                    if (_this.is_starred &&
                                        !_this.starredListSubscription &&
                                        _this.syncId == _this.userService.get('syncus_sync_id')) {
                                        _this.showFavoriteList();
                                    }
                                }
                                else {
                                    _this.list = [];
                                }
                                // this.isLoaded = true;
                                _this.histId = data.hist_id;
                                _this.paginate.total = _this.list.length;
                                _this.paginate.numPages = Math.ceil(_this.list.length / _this.paginate.itemsPerPage);
                                if (_this.route.snapshot.queryParams['page'] &&
                                    parseInt(_this.route.snapshot.queryParams['page'], 10) <=
                                        _this.paginate.numPages) {
                                    _this.paginate.currentPage =
                                        parseInt(_this.route.snapshot.queryParams['page'], 10) || 1;
                                }
                                else {
                                    _this.paginate.currentPage = 1;
                                }
                                _this.thumbnailsService.getThumbs(_this.list, _this.paginate);
                                _this.isLoaded = data.loaded;
                            }
                        });
                        this.predicate = this.route.snapshot.queryParams['predicate']
                            ? this.route.snapshot.queryParams['predicate']
                            : 'default';
                        this.predicateReverse =
                            this.route.snapshot.queryParams['predicateReverse'] == 'false' ? false : true;
                        this.fileListService.sortList(this.predicate, this.predicateReverse);
                        this.showGrid = this.route.snapshot.queryParams['view'] == 'grid';
                        input = new models_1.PathListApiInput();
                        input.sync_id = this.route.snapshot.params['sync_id']
                            ? parseInt(this.route.snapshot.params['sync_id'], 10)
                            : this.defaultSyncId;
                        input.hist_id = parseInt(this.route.snapshot.queryParams['histId'], 10) || 0;
                        input.showdeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
                        input.is_vault = this.isVault;
                        this.fileListService.dispatch(input);
                        // this.paginate.currentPage = parseInt(this.route.snapshot.queryParams['page'], 10) || 1,
                        this.showDeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
                        this.histId = parseInt(this.route.snapshot.queryParams['histId'], 10) || 0;
                        if (this.route.snapshot.queryParams['loc']) {
                            this.dispatchService.run();
                        }
                        this.syncId = this.route.snapshot.params['sync_id']
                            ? parseInt(this.route.snapshot.params['sync_id'], 10)
                            : this.defaultSyncId;
                        this.isRoot = this.defaultSyncId == this.syncId;
                        // this.getList(this.syncId);
                        this.hasAppsNewFeature =
                            this.userService.get('new_feature') === 1 &&
                                this.userService.get('app_count') > 0;
                        if (this.userService.get('is_on_trial') &&
                            this.userService.checkPermission(models_1.PermissionActions.MANAGE_BILLING)) {
                            this.loadBillingDetails();
                        }
                        _a = this;
                        return [4 /*yield*/, this.skuService.isFreeUser()];
                    case 1:
                        _a.is_FreeUser = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileListComponent.prototype.filterList = function () {
        var filterString = this.filter ? this.filter.toLowerCase() : '';
        var filter = this.filter
            ? this.list.filter(function (item) {
                return item.name.toLowerCase().includes(filterString) ||
                    item.date_medium.toLowerCase().includes(filterString) ||
                    item.filesize.toLowerCase().includes(filterString);
            })
            : this.list;
        this.filteredIds = this.filter ? filter.map(function (item) { return item.id; }) : [];
        filter = filter.slice((this.paginate.currentPage - 1) * this.paginate.itemsPerPage, (this.paginate.currentPage - 1) * this.paginate.itemsPerPage +
            this.paginate.itemsPerPage);
        var filterCount = filter.length;
        // this.loggerService.d(
        //     'fitler count changed ' + typeof this.filter + ' -- ' + filterCount
        // );
        if (typeof this.filter == 'string' && this.filter.length > 1) {
            //this.paginate.total = filterCount;
            this.paginate.numPages = Math.ceil(filterCount / this.paginate.itemsPerPage);
        }
        else if (this.list && this.list.length) {
            this.paginate.total = this.list.length;
            this.paginate.numPages = Math.ceil(this.list.length / this.paginate.itemsPerPage);
        }
        else {
            // this.Logger.warn('ended up igetSubscriptionn incorrect filterCount')
        }
        return filter;
    };
    FileListComponent.prototype.navigateToRoot = function () {
        this.router.navigate(['..'], {
            relativeTo: this.route,
            queryParams: { sync_id: null },
            queryParamsHandling: 'merge'
        });
    };
    FileListComponent.prototype.isBackNavigation = function () {
        var isBack = this.router.url === this.previousUrl;
        this.previousUrl = this.router.url; // Update the previous URL for the next check
        return isBack;
    };
    FileListComponent.prototype.reloadComponent = function () {
        // Perform any actions needed before reloading the component
        // For example, fetch data, reset variables, etc.
        // Reload the component by navigating to the same route
        this.router.navigate([this.router.url]);
    };
    FileListComponent.prototype.navigateToSubFolder = function (item) {
        this.router.navigate(['..', item.loc_id], {
            relativeTo: this.route,
            queryParams: { page: undefined },
            queryParamsHandling: 'merge'
        });
    };
    FileListComponent.prototype.hasPermission = function (permissionKey) {
        return (this.userService.getPermssionByKey(permissionKey));
    };
    FileListComponent.prototype.trackByFn = function (index, item) {
        return item.sync_id;
    };
    FileListComponent.prototype.getImage = function (image) {
        if (image) {
            return this._sanitizer.bypassSecurityTrustUrl(image);
        }
        return null;
    };
    FileListComponent.prototype.openShareDetails = function (selected, openState) {
        var webpath = Array.isArray(selected) ? selected[0] : selected;
        var shareId = webpath.shareId || webpath.share_id;
        var state = !Number.isNaN(parseInt(openState, 10)) ? parseInt(openState, 10) : 0;
        this.shareService.openDetails(shareId, state);
    };
    FileListComponent.prototype.ngOnDestroy = function () {
        // console.error('ON DESTROY IS CALLED ON FILE LIST CONTROLLER');
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
        if (this.starredListSubscription) {
            this.starredListSubscription.unsubscribe();
        }
        this.fileListService.reset();
        this.router.routeReuseStrategy.shouldReuseRoute = this.saveRouterStrategyReuseLogic;
    };
    FileListComponent.prototype.setRenameState = function (syncId) {
        if (syncId && syncId > 0) {
            this.list.map(function (cur) {
                if (cur.sync_id == syncId) {
                    cur.rename = true;
                    return;
                }
            });
        }
    };
    FileListComponent.prototype.reload = function (params) {
        if (this.histId && params && params.hist_id == this.histId) {
            this.loggerService.info('skipping file list refresh ' +
                this.histId +
                ' - ' +
                params.hist_id);
            return;
        }
        if (this.isLoaded) {
            var input = new models_1.PathListApiInput();
            input.sync_id = this.cwd.sync_id;
            input.hist_id = 0;
            input.showdeleted = parseInt(this.route.snapshot.queryParams['showDeleted'], 10);
            input.is_vault = this.isVault;
            this.isLoaded = false;
            if (this.is_starred && this.syncId == this.userService.get('syncus_sync_id')) {
                this.showFavoriteList();
                this.isLoaded = true;
            }
            else {
                this.fileListService.dispatch(input);
            }
        }
        else {
            this.loggerService.info('skipping file list refresh, isLoaded = false');
        }
    };
    FileListComponent.prototype.toggleFilter = function () {
        this.showFilter = !this.showFilter;
        if (this.showFilter === true) {
            this.paginate.currentPage = 1;
        }
        else if (!this.showFilter && this.route.snapshot.queryParams['page']) {
            this.paginate.currentPage = parseInt(this.route.snapshot.queryParams['page'], 10);
        }
    };
    FileListComponent.prototype.toggleDeleted = function () {
        var _this = this;
        var prev = this.router.routeReuseStrategy.shouldReuseRoute;
        var showDel = this.showDeleted == 1 ? 0 : 1;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { showDeleted: showDel },
            queryParamsHandling: 'merge'
        }).then(function () {
            _this.router.routeReuseStrategy.shouldReuseRoute = prev;
        });
    };
    FileListComponent.prototype.changePage = function () {
        var params = this.paginate.currentPage > 1
            ? { page: this.paginate.currentPage }
            : { page: undefined };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge'
        });
        this.thumbnailsService.getThumbs(this.list, this.paginate);
    };
    FileListComponent.prototype.getPredicateClass = function (field) {
        var cssClass = 'fa fa-sortx text-muted';
        if (this.predicate &&
            this.predicate.indexOf(field) > -1 &&
            this.predicateReverse) {
            cssClass = 'fa fa-sort-down';
        }
        else if (this.predicate &&
            this.predicate.indexOf(field) > -1 &&
            !this.predicateReverse) {
            cssClass = 'fa fa-sort-up';
        }
        return cssClass;
    };
    FileListComponent.prototype.setPredicate = function (field) {
        if (this.predicate == 'name' && this.predicateReverse === true) {
            this.predicate = 'default';
            this.predicateReverse = true;
        }
        else {
            this.predicate = field;
            this.predicateReverse = !this.predicateReverse;
        }
        this.router.navigate([], {
            relativeTo: this.route, queryParams: {
                predicate: this.predicate,
                predicateReverse: this.predicateReverse,
            },
            queryParamsHandling: 'merge'
        });
        this.fileListService.sortList(this.predicate, this.predicateReverse);
    };
    FileListComponent.prototype.toggleView = function () {
        this.broadcastService.broadcast('event:hide-tools');
        var newView = this.showGrid ? 'list' : 'grid';
        this.showGrid = !this.showGrid;
        this.router.navigate([], {
            relativeTo: this.route, queryParams: {
                view: newView,
            },
            queryParamsHandling: 'merge'
        });
    };
    FileListComponent.prototype.hasSelected = function () {
        var selected = this.fileSelectionService.getSelected();
        return selected.length && this.showFileMenu;
    };
    FileListComponent.prototype.select = function (item) {
        item.active = true;
        this.fileSelectionService.select(item);
    };
    FileListComponent.prototype.selectToggle = function (item) {
        this.fileSelectionService.toggle(item);
        this.showFileMenu = this.fileSelectionService.getSelected().length > 0;
    };
    FileListComponent.prototype.selectOne = function (item) {
        this.selectAll(false);
        this.fileSelectionService.select(item);
    };
    FileListComponent.prototype.openMenu = function (clickedDropdown) {
        this.dropdowns.toArray().forEach(function (el) {
            if (el !== clickedDropdown) {
                el.close();
            }
        });
    };
    FileListComponent.prototype.openUploadFolderModal = function () {
        this.uploadFolderService.showFolderUpload();
    };
    FileListComponent.prototype.selectType = function (field, value) {
        this.selectAll(false);
        this.fileSelectionService.selectType(field, value, this.filteredIds);
        this.showFileMenu = true;
    };
    FileListComponent.prototype.selectAll = function (val) {
        if (val === void 0) { val = true; }
        this.showFileMenu = val;
        this.fileSelectionService.selectAll(val, this.filteredIds);
    };
    FileListComponent.prototype.openAppList = function () {
        var _this = this;
        var ref = this.modalService.open(dialog_share_app_list_component_1.DialogShareAppListComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(function () { return _this.fileSelectionService.getSelected()[0]; });
    };
    FileListComponent.prototype.openCreateBlankDocumentModal = function () {
        var ref = this.modalService.open(dialog_create_blank_document_component_1.DialogCreateBlankDocumentComponent, {
            backdropClass: 'in',
            windowClass: 'in',
            backdrop: 'static',
        });
        ref.result.then(function () { });
    };
    FileListComponent.prototype.loadBillingDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.zuora.getProfileAndQueue()];
                    case 1:
                        data = _a.sent();
                        if (data.zprofile &&
                            data.zprofile.subscription &&
                            data.zprofile.subscription.termEndDate) {
                            this.planExpiry = this.userService.dateConversionForTrials(data.zprofile.subscription.termEndDate, this.user.is_on_trial).getTime();
                        }
                        if (data.zprofile && data.zprofile.defaultPaymentMethod) {
                            this.showFreeTrialReminder = false;
                            if (!data.zprofile.autoPay) {
                                this.showPayNowBanner = true;
                            }
                        }
                        else {
                            this.showFreeTrialReminder = true;
                            this.showPayNowBanner = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    FileListComponent.prototype.shouldLoadList = function (data) {
        if (this.isRoot) {
            return (data.pathlist.length &&
                data.pathlist[0].is_vault === this.isVault);
        }
        else {
            return data.sync_id == +(this.route.snapshot.params['sync_id'] || 0);
        }
    };
    FileListComponent.prototype.itemLink = function (item) {
        this.user = this.userService.getUser();
        var ctx = item.context == 'link' || item.context == 'applink' ? 'p' : 'u';
        var type = 'default';
        if (item.type == 'dir') {
            if (this.router.url.includes('files')) {
                return "/files/" + item.sync_id;
            }
            if (this.router.url.includes('vault')) {
                return "/vault/" + item.sync_id;
            }
        }
        else if (this.fileItemService.isImage(item)) {
            type = 'image';
        }
        else if (this.fileItemService.isText(item)) {
            type = 'text';
        }
        else if (this.fileItemService.isPDF(item) &&
            (item.context == 'link' || item.context == 'applink') &&
            item.previewonly) {
            type = 'doc';
        }
        else if (this.fileItemService.isPDF(item) &&
            item.context == 'files') {
            type = 'doc';
        }
        else if (this.display_office(item)) {
            type = 'office';
        }
        else if (this.display_doc(item)) {
            type = 'doc';
        }
        else {
            type = 'default';
        }
        return "/file/" + item.sync_id + "/view/" + type;
    };
    FileListComponent.prototype.getQueryParams = function (item) {
        var queryParams = {};
        if (item.type == 'dir') {
            queryParams = this.route.snapshot.queryParams;
        }
        else {
            for (var _i = 0, _a = Object.entries(this.route.snapshot.queryParams); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], val = _b[1];
                if (key === 'view' || key === 'page') {
                    queryParams[key] = val;
                }
            }
        }
        return queryParams;
    };
    FileListComponent.prototype.display_doc = function (item) {
        return (this.fileItemService.isDocument(item) &&
            // only compat links
            ((item.compat &&
                (item.context == 'link' || item.context == 'applink')) ||
                item.context == 'files'));
    };
    FileListComponent.prototype.display_office = function (item) {
        return (this.fileItemService.isMSOffice(item, 'embedview') &&
            // Only enabled in files, for now
            item.context === 'files' &&
            this.user.is_office === 1);
    };
    FileListComponent.prototype.showFavoriteList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showfavorites = this.showfavorites ? false : true;
                        if (!this.is_starred) return [3 /*break*/, 2];
                        this.subscription.unsubscribe();
                        return [4 /*yield*/, this.starredListService.showFavorites(this.syncId)];
                    case 1:
                        _a.sent();
                        this.starredListSubscription = this.starredListService
                            .getFavoritsList()
                            .subscribe(function (data) {
                            if (data.error) {
                                _this.showfavorites = false;
                                _this.favSpinner = false;
                                _this.errcode = new models_1.ErrCode(1800, 'Unable to access your starred right now. Please retry later.');
                            }
                            else {
                                if (_this.is_starred && _this.isRoot && _this.predicate == 'default') {
                                    _this.list = data.pathlist
                                        .filter(function (item) { return item.is_favorite; })
                                        .sort(function (a, b) { return b.tag_id - a.tag_id; });
                                    _this.favSpinner = false;
                                }
                                else {
                                    _this.list = data.pathlist
                                        .filter(function (item) { return item.is_favorite; });
                                    _this.favSpinner = false;
                                }
                            }
                        });
                        this.blendService.track(models_1.BlendEvent.VIEW_FAVORITE_LIST, {});
                        return [3 /*break*/, 3];
                    case 2:
                        this.starredListSubscription.unsubscribe();
                        this.broadcastService.broadcast('event:file-list.reload');
                        this.showfavorites = false;
                        this.favSpinner = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FileListComponent.prototype.addFavoriteTag = function (SyncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file_extension, file_size, mime_type, isDir, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file_extension = '', file_size = 0, mime_type = '';
                        this.starredListService.setTotalFavorite(this.total_favorite);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        if (!(this.is_FreeUser && this.total_favorite >= 3)) return [3 /*break*/, 2];
                        this.starredListService.openAccountUpgradeDialog();
                        return [3 /*break*/, 4];
                    case 2:
                        this.list.map(function (item) {
                            if (item.sync_id == SyncId && !item.is_favorite) {
                                item.is_favorite = true;
                                item.is_tagEnabled = true;
                            }
                        });
                        return [4 /*yield*/, this.starredListService.addTag([SyncId], this.user.uid)];
                    case 3:
                        _a.sent();
                        this.list.map(function (item) {
                            if (item.sync_id == SyncId && item.is_favorite) {
                                item.is_tagEnabled = false;
                                file_extension = item.file_extension;
                                file_size += item.size;
                                mime_type = item.mime_type;
                                isDir = item.type == 'dir' ? true : false;
                            }
                        });
                        this.blendService.track(models_1.BlendEvent.ADD_FAVORITE_TAG, isDir
                            ? {}
                            : {
                                fileSize: this.fileItemService.bytesToSize(file_size),
                                mimeType: mime_type
                            });
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        this.feedbackService.setFeedback('danger', "Failed to add star", 1801);
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    FileListComponent.prototype.removeFavoriteTag = function (sync_id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file_extension, file_size, mime_type, isDir, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file_extension = '', file_size = 0, mime_type = '';
                        this.starredListService.setTotalFavorite(this.total_favorite);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.list.map(function (item) {
                            if (item.sync_id == sync_id && item.is_favorite) {
                                item.is_favorite = false;
                                item.is_tagEnabled = true;
                                file_extension = item.file_extension;
                                file_size += item.size;
                                mime_type = item.mime_type;
                                isDir = item.type == 'dir' ? true : false;
                            }
                        });
                        return [4 /*yield*/, this.starredListService.removeTag([sync_id])];
                    case 2:
                        _a.sent();
                        this.list.map(function (item) {
                            if (item.sync_id == sync_id && !item.is_favorite) {
                                item.is_tagEnabled = false;
                            }
                        });
                        if (this.showfavorites) {
                            this.starredListSubscription.unsubscribe();
                            this.showfavorites = false;
                            this.showFavoriteList();
                        }
                        this.blendService.track(models_1.BlendEvent.REMOVE_FAVORITE_TAG, isDir
                            ? {}
                            : {
                                fileSize: this.fileItemService.bytesToSize(file_size),
                                mimeType: mime_type
                            });
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.feedbackService.setFeedback('danger', "Failed to remove star", 1802);
                        this.broadcastService.broadcast('event:file-list.reload');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return FileListComponent;
}());
exports.FileListComponent = FileListComponent;
