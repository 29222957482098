"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var func_1 = require("../../shared/func");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var user_service_1 = require("../../core/user.service");
var browser_support_service_1 = require("../../core/browser-support.service");
var ng_bootstrap_1 = require("@ng-bootstrap/ng-bootstrap");
var build_transfer_item_service_1 = require("../services/build-transfer-item.service");
var router_1 = require("@angular/router");
var transfer_model_1 = require("../transfer.model");
var models_1 = require("../../shared/models");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var transfer_item_service_1 = require("../services/transfer-item.service");
var blend_service_1 = require("src/app/shared/services/blend.service");
var TransferComponent = /** @class */ (function () {
    function TransferComponent(buildTransferItemService, linkFileListService, userService, browserSupportService, activeModal, router, broadcastService, transferItemService, blendService) {
        this.buildTransferItemService = buildTransferItemService;
        this.linkFileListService = linkFileListService;
        this.userService = userService;
        this.browserSupportService = browserSupportService;
        this.activeModal = activeModal;
        this.router = router;
        this.broadcastService = broadcastService;
        this.transferItemService = transferItemService;
        this.blendService = blendService;
    }
    TransferComponent.prototype.trackbyFunction = function (index) {
        return index;
    };
    TransferComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.transferView = this.buildTransferItemService.view;
                this.router.url.includes('/dl/') ? this.isPublic = 1 : this.isPublic = 0;
                this.subscription = this.linkFileListService
                    .getSubscription()
                    .subscribe(function (data) {
                    // do not check path list length as empty folder still needs to be uploadable
                    if (data && data.pathlist && data.loaded && data.sorted) {
                        _this.uploadable = data.upload > 0;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    TransferComponent.prototype.testFileUpload = function () {
        return this.browserSupportService.testFileUpload();
    };
    TransferComponent.prototype.testDirUpload = function () {
        return this.browserSupportService.testDirUpload();
    };
    TransferComponent.prototype.showUploadBtn = function () {
        if (this.isPublic) {
            return this.uploadable;
        }
        else {
            return (this.router.url.includes('/files') ||
                this.router.url.includes('/vault'));
        }
    };
    TransferComponent.prototype.onDisplayRetryBtn = function () {
        if (!this.isDisplayRetryButtonEventLogged) {
            for (var _i = 0, _a = this.transferView.upload.queue; _i < _a.length; _i++) {
                var transferItem = _a[_i];
                if (transferItem.status >= 7000
                    && transferItem.status <= 7244
                    && transferItem.type == 20
                    && transferItem.retryCount < 3) {
                    this.blendService.track(models_1.BlendEvent.RETRY_UPLOAD_BUTTON_DISPLAYED);
                }
            }
            this.isDisplayRetryButtonEventLogged = true;
        }
        return true;
    };
    TransferComponent.prototype.clear = function () {
        this.transferView.download.queue = this.transferView.download.queue.filter(function (transferItem) {
            return (transferItem.status != 1 && transferItem.status <= 1000) ||
                ![10, 20].includes(transferItem.type);
        });
        this.transferView.upload.queue = this.transferView.upload.queue.filter(function (transferItem) {
            return (transferItem.status != 1 && transferItem.status <= 1000) ||
                ![10, 20].includes(transferItem.type);
        });
    };
    TransferComponent.prototype.downloadCompatible = function (file) {
        this.buildTransferItemService
            .getCompatUrl(file, this.buildTransferItemService.ACT_DOWNLOAD)
            .then(function (data) { return (window.location.href = data); });
    };
    TransferComponent.prototype.sort = function (a, b) {
        return func_1.compare(a._initTime, b._initTime, true);
    };
    TransferComponent.prototype.uploadFileSelect = function (event) {
        this.ufile = event.target.files;
    };
    TransferComponent.prototype.cancelUpload = function (transferItem) {
        if (transferItem.status >= 10 && transferItem.status <= 20) {
            transferItem.status = transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED;
            this.transferView.upload.isProcessing = false;
            if (this.transferView.upload.queue.length > 0) {
                this.buildTransferItemService.runQueue(transfer_model_1.TransferQueueType.UPLOAD);
            }
        }
        else {
            transferItem.status = transfer_model_1.TransferStatus.STATUS_UPLOAD_CANCELLED;
        }
    };
    TransferComponent.prototype.retryUpload = function (transferItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(transferItem.status >= 7000 && transferItem.status <= 7244 && transferItem.retryCount < 3)) return [3 /*break*/, 2];
                        ++transferItem.retryCount;
                        transferItem.chunkqueue = this.transferItemService.getChunkQueue(transferItem);
                        transferItem.bytes_sent = 0;
                        transferItem.progress_percent = 0;
                        transferItem.progress_network = 0;
                        transferItem.progress_crypt = 0;
                        transferItem.status = transfer_model_1.TransferStatus.STATUS_WAITING;
                        return [4 /*yield*/, this.buildTransferItemService.runQueue(transfer_model_1.TransferQueueType.UPLOAD)];
                    case 1:
                        _a.sent();
                        this.blendService.track(models_1.BlendEvent.BUTTON_CLICKED, { button_text: 'Retry upload' });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return TransferComponent;
}());
exports.TransferComponent = TransferComponent;
