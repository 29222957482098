import { Injectable } from '@angular/core';
import { Observable, Subject, timer, EMPTY } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, retryWhen, tap, delayWhen, switchMap } from 'rxjs/operators';
import { User } from '../shared/models/user.model';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { BaseApiInput } from '../shared/models';
import { SyncCryptService } from './crypt/sync-crypt.service';
import * as msgpack from 'msgpack-lite';
import { LoggerService } from './logger.service';
import { MessageType, WebSocketMessage } from '../shared/models/websocket/websocket-message';
import { ProgressPayload, UserUpdatePayload } from '../shared/models/websocket/action-payload';
import { UrlService } from './url.service';

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private subject: WebSocketSubject<any>;
    private connection$: Observable<any>;
    private connectionPool: string[];
    private maxReconnectAttempts = 2;
    private currentServerIndex = 0;
    private retryDelay = 1000;

    // General subject to handle dynamic message broadcasting
    private messageSubject = new Subject<WebSocketMessage>();

    // Exposed observable for components to subscribe to all messages
    public messages$ = this.messageSubject.asObservable();

    // Map to hold handlers for different message types
    private messageHandlers = new Map<MessageType, Subject<any>>();

    public user: User;

    constructor(
        private store: Store<fromRoot.State>,
        private crypt: SyncCryptService,
        private loggerService: LoggerService,
        private urlService: UrlService,
    ) {
        this.store.pipe(select(fromRoot.getAuthUser)).subscribe(data => {
            this.user = data;
        });

        // Initialize handlers for different MessageTypes
        this.messageHandlers.set(MessageType.LINK_NEW_PROGRESS, new Subject<ProgressPayload>());
        this.messageHandlers.set(MessageType.UPDATE_USER, new Subject<UserUpdatePayload>());
        // Additional handlers can be added here as needed
    }

    public async connect(): Promise<void> {
        this.connectionPool = this.urlService.getHostByType('news');

        if (this.connectionPool.length === 0) {
            this.loggerService.warn('No websocket connection pool found');
            return;
        }

        if (this.subject && !this.subject.closed) {
            return; // If connection already exists and is open, do not create a new one
        }

        const serverUrl = this.connectionPool[this.currentServerIndex];
        const params = new BaseApiInput();
        const inputHeaders = await this.crypt.signApiReq(params);
        const webSocketUrl = serverUrl
            + '/multisub'
            + `/cpanel${this.user.uid}`;

        // Concatenate the auth params and convert to hex
        const authString = `${inputHeaders.access_token},${inputHeaders.servtime},${inputHeaders.signature}`;
        const authHex = this.crypt.bytesToHex(new TextEncoder().encode(authString));

        this.subject = webSocket({
            url: webSocketUrl,
            binaryType: 'arraybuffer',
            protocol: ['ws+meta.nchan', `Auth${authHex}`],
            deserializer: (e) => {
                try {
                    this.loggerService.info('WebSocket message deserializer:: ');
                    this.loggerService.info(e);

                    // Helper function to parse metadata from text
                    const parseMetadata = (text: string) =>
                        text.split('\n').reduce((meta, line) => {
                            const [key, value] = line.split(': ');
                            if (key && value) { meta[key.trim()] = value.trim(); }
                            return meta;
                        }, {} as Record<string, string>);

                    // Convert data to a string depending on its type
                    const data = typeof e.data === 'string'
                        ? e.data // Handle text/plain payload
                        : new TextDecoder().decode(new Uint8Array(e.data)); // Handle application/octet-stream payload

                    // Find the boundary between metadata and the message body
                    const splitIndex = data.indexOf('\n\n');
                    if (splitIndex === -1) {
                        this.loggerService.error('Invalid message format: No metadata delimiter found');
                        return null;
                    }

                    // Parse and log metadata
                    const metadata = parseMetadata(data.substring(0, splitIndex));
                    this.loggerService.info('Message Metadata:: ');
                    this.loggerService.info(metadata);

                    // Extract the message body
                    const messageData = data.substring(splitIndex + 2);

                    // Handle different payload types based on the original data type
                    if (typeof e.data === 'string') {
                        // Payload is a JSON-encoded string
                        try {
                            const jsonMessage = JSON.parse(messageData);
                            this.loggerService.info('Message Data:: ');
                            this.loggerService.info(jsonMessage);
                            return jsonMessage;
                        } catch (jsonError) {
                            this.loggerService.error('Error parsing JSON message data:: ');
                            this.loggerService.error(jsonError);
                            return null;
                        }
                    } else {
                        // Payload is MessagePack-encoded binary data
                        try {
                            const messageBuffer = new Uint8Array(e.data, splitIndex + 2);
                            const decodedMessage = msgpack.decode(messageBuffer);
                            this.loggerService.info('Decoded Message:: ');
                            this.loggerService.info(decodedMessage);
                            return decodedMessage;
                        } catch (decodeError) {
                            this.loggerService.error('Error decoding MessagePack data:: ');
                            this.loggerService.error(decodeError);
                            return null;
                        }
                    }
                } catch (err) {
                    this.loggerService.error('Error deserializing websocket payload:: ');
                    this.loggerService.error(err);
                    return null;
                }
            }
        });

        this.connection$ = this.subject.pipe(
            retryWhen(errors =>
                errors.pipe(
                    delayWhen(() => timer(this.retryDelay)),
                    tap(() => this.loggerService.info(`Retry attempt on server: ${serverUrl}`)),
                    switchMap((error, index) => {
                        this.loggerService.error('Error connecting websocket');
                        this.loggerService.error(error);
                        if (index + 1 >= this.maxReconnectAttempts) {
                            this.switchToNextServer();
                            return EMPTY;
                        } else {
                            return timer(this.retryDelay);
                        }
                    })
                )
            ),
            catchError(err => {
                this.loggerService.error('WebSocket error:: ');
                this.loggerService.error(err);
                this.switchToNextServer();
                return EMPTY;
            })
        );

        this.connection$.subscribe(
            (msg: WebSocketMessage) => {
                try {
                    this.loggerService.info('WebSocket message received:: ');
                    this.loggerService.info(msg);

                    // Broadcast the message to the general subject
                    this.messageSubject.next(msg);

                    // Route to the appropriate handler based on MessageType
                    const handler = this.messageHandlers.get(msg.type);
                    if (handler) {
                        handler.next(msg.payload);
                    } else {
                        this.loggerService.warn(`No handler found for message type: ${msg.type}`);
                    }
                } catch (err) {
                    this.loggerService.error('Error processing message:: ');
                    this.loggerService.error(err);
                }
            },
            (err) => {
                this.loggerService.error('Connection error:: ');
                this.loggerService.error(err);
                this.switchToNextServer();
            },
            () => {
                this.loggerService.error('Connection closed');
                this.switchToNextServer();
            }
        );
    }

    public getMessageHandler<T>(type: MessageType): Observable<T> {
        const handler = this.messageHandlers.get(type);
        if (!handler) {
            const newHandler = new Subject<T>();
            this.messageHandlers.set(type, newHandler);
            return newHandler.asObservable();
        }
        return handler.asObservable();
    }

    private switchToNextServer(): void {
        this.clearSubject();
        this.currentServerIndex = (this.currentServerIndex + 1) % this.connectionPool.length;
        this.connect();
    }

    private clearSubject(): void {
        if (this.subject) {
            this.subject.complete();
            this.subject = null; // Nullify the subject to allow reconnection
        }
    }

    public close(): void {
        this.clearSubject();
    }
}
