"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var store_1 = require("@ngrx/store");
var logger_service_1 = require("src/app/core/logger.service");
var link_file_list_service_1 = require("src/app/link-consume/services/link-file-list.service");
var environment_1 = require("src/environments/environment");
var models_1 = require("../models");
var sku_service_1 = require("./sku.service");
var fromRoot = require("../../reducers");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var memoization_service_1 = require("./memoization.service");
var router_1 = require("@angular/router");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./sku.service");
var i3 = require("../../core/logger.service");
var i4 = require("../../link-consume/services/link-file-list.service");
var i5 = require("./memoization.service");
var i6 = require("@ngrx/store");
var i7 = require("@angular/router");
var FeatureService = /** @class */ (function () {
    function FeatureService(httpClient, skuService, log, LinkPathList, memoizationService, store, router) {
        this.httpClient = httpClient;
        this.skuService = skuService;
        this.log = log;
        this.LinkPathList = LinkPathList;
        this.memoizationService = memoizationService;
        this.store = store;
        this.router = router;
        this.path = environment_1.environment.featureHost;
        this.features = [];
        this.EXPIRY = 43200000; // 12 hours
        this.featureCache$ = null;
        this.getFeatureData();
    }
    FeatureService.prototype.getUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.user) {
                            return [2 /*return*/];
                        }
                        if (!this.router.url.includes('/dl')) return [3 /*break*/, 2];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.linkSubscribe = _this.LinkPathList.getSubscription()
                                    .pipe(operators_1.take(1))
                                    .subscribe(function (data) {
                                    resolve(data);
                                });
                            })];
                    case 1:
                        data = _a.sent();
                        if (data && data.owner_id) {
                            this.user = new models_1.User({
                                id: data.owner_id,
                                team_id: data.team_id,
                                email: data.email,
                                sku: data.sku,
                                is_multi_admin: data.is_multi_admin,
                                is_on_trial: data.is_on_trial,
                                is_link_user: true,
                            });
                            this.unsubscribe(this.linkSubscribe);
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, new Promise(function (resolve) {
                            _this.userSubscribe = _this.store
                                .pipe(store_1.select(fromRoot.getAuthUser), operators_1.take(1))
                                .subscribe(function (data) {
                                resolve(data);
                            });
                        })];
                    case 3:
                        data = _a.sent();
                        if (data) {
                            this.user = data;
                            this.unsubscribe(this.userSubscribe);
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    FeatureService.prototype.getFeatureData = function () {
        var _this = this;
        this.unsubscribe(this.featureSubscribe);
        this.featureSubscribe = this.getFeatures().subscribe(function (response) {
            _this.features = response || [];
        }, function (error) {
            _this.features = [];
            _this.log.e('Failed API feature', error);
        });
    };
    FeatureService.prototype.getFeatures = function () {
        var _this = this;
        var featuresUrl = this.path + "features";
        // If cached, return it
        if (this.featureCache$) {
            return this.featureCache$;
        }
        // Cache the observable result
        this.featureCache$ = this.memoizationService.memoize('memoize_feature', function () {
            return _this.httpClient.get(featuresUrl).pipe(operators_1.retryWhen(function (errors) {
                return errors.pipe(
                // Retry up to 3 times with a delay of 1 second between retries
                operators_1.delay(1000), operators_1.take(3), operators_1.catchError(function (error) {
                    _this.log.e('API request error', error);
                    return rxjs_1.of([]); // Return empty array on error
                }));
            }), operators_1.shareReplay(1) // Cache and share the last emitted value
            );
        });
        return this.featureCache$;
    };
    FeatureService.prototype.isExpiredData = function () {
        return this.user.id !== this.previousUserId;
    };
    FeatureService.prototype.getFeatureConfigs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var featureFlag, sku;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        featureFlag = {};
                        sku = 'free';
                        if (!!this.user['is_link_user']) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.skuService.getSku()];
                    case 1:
                        sku = _a.sent();
                        this.user.sku = sku;
                        return [3 /*break*/, 3];
                    case 2:
                        this.user.sku = this.user.sku || sku;
                        _a.label = 3;
                    case 3:
                        if (this.features && this.features.length > 0) {
                            this.features.forEach(function (feature) {
                                featureFlag[feature.feature_name] =
                                    feature.enabled && _this.isFeatureAllowed(feature, _this.user);
                            });
                        }
                        else {
                            this.getFeatureData();
                        }
                        return [2 /*return*/, featureFlag];
                }
            });
        });
    };
    FeatureService.prototype.isAllowed = function (featureName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getUser()];
                    case 1:
                        _b.sent();
                        if (!(!this.featureFlag || this.isExpiredData())) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.getFeatureConfigs()];
                    case 2:
                        _a.featureFlag = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, this.featureFlag[featureName] || false];
                }
            });
        });
    };
    FeatureService.prototype.isFeatureAllowed = function (feature, user) {
        return (this.isValueEndsWithCriteria(feature.userid_criteria, user.id) &&
            this.isValueEndsWithCriteria(feature.email_criteria, user.email) &&
            this.isValueHasCriteria(feature.plan_criteria, user.sku) &&
            this.isValueEndsWithCriteria(feature.teams_criteria, user.team_id) &&
            this.isValueHasCriteria(feature.country_criteria, Intl.DateTimeFormat().resolvedOptions().timeZone));
    };
    FeatureService.prototype.isValueEndsWithCriteria = function (criteria, value) {
        value = value || '';
        return criteria ? new RegExp("(" + criteria + ")$", 'i').test(value.toString()) : true;
    };
    FeatureService.prototype.isValueHasCriteria = function (criteria, sku) {
        return criteria ? new RegExp("(" + criteria + ")", 'i').test(sku) : true;
    };
    FeatureService.prototype.resetFeatureFlags = function () {
        this.featureFlag = null;
        this.user = null;
        this.featureCache$ = null; // Reset the cache
    };
    FeatureService.prototype.unsubscribe = function (subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    };
    FeatureService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.inject(i1.HttpClient), i0.inject(i2.SkuService), i0.inject(i3.LoggerService), i0.inject(i4.LinkFileListService), i0.inject(i5.MemoizationService), i0.inject(i6.Store), i0.inject(i7.Router)); }, token: FeatureService, providedIn: "root" });
    return FeatureService;
}());
exports.FeatureService = FeatureService;
